import { Alert, Button, CloseButton, Text, TextInput } from "@mantine/core"
import { useProviders } from "modules/providers"
import ProviderCard from "modules/providers/ProviderCard"
import { useFormik } from "formik"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { Scale, Calendar, InfoCircle, Search, Send } from "tabler-icons-react"
import styles from "./index.module.scss"
import AppPage from "components/AppPage"
import join from "clsx"
import PageHeader from "components/PageHeader"
import { getUserInfo } from "modules/auth"
import { showRequestCreate } from "modules/requests"
import { useSelector } from "lodash-redux"
import { selectLoaders } from "core/loaders"
import TagFilters from "components/TagFilters"
import { includesLowerCase } from "core"
import { PageContext, updateValues } from "core/pages"


function ProviderList({ providerId, provider_search = "", specializationId = "" }) {
  const { t } = useTranslation()
  const { compact } = React.useContext(PageContext)
  const showingLoaders = useSelector(() => selectLoaders("showRequestCreate"))

  const [providers, loading] = useProviders()
  const specializations = _.uniqBy(Object.values(providers)
    .map(({ specializations = [] }) => specializations)
    .flat()
  , "id")

  const filteredList = React.useMemo(() => {
    return Object.values(providers)
      .filter((provider) => {
        const conditions = []
        if (specializationId) {
          const { specializations = [] } = provider
          conditions.push(_.some(specializations, { id: specializationId }))
        }
        if (!_.isEmpty(provider_search)) {
          const { specializations = [], profile } = provider
          const { address } = profile || {}
          const { city } = address || {}
          const { name = "", title = "" } = getUserInfo(provider)
          conditions.push((
            includesLowerCase(title, provider_search) ||
            includesLowerCase(name, provider_search) ||
            includesLowerCase(city, provider_search) ||
            specializations.some(({ name }) => includesLowerCase(t(name), provider_search))
          ))
        }
        return conditions.every(Boolean)
      })
  }, [provider_search, specializationId, providers])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      provider_search,
      specializationId
    },
    validate: (values) => updateValues(values)
  })
  const { values } = formik

  return (
    <AppPage
      loading={loading && _.isEmpty(filteredList)}
      name={t("providers")}
      className={join(styles.providerList, compact && styles.compact)}
      header={
        <PageHeader
          icon={<Scale />}
          name={t("providers")}
          toolbar={
            <div className={styles.toolbar}>
              <TextInput
                name="provider_search"
                className={styles.search}
                variant="filled"
                icon={<Search />}
                placeholder={t("search_for_providers")}
                value={values.provider_search}
                onChange={formik.handleChange}
                rightSection={values.provider_search &&
                  <CloseButton
                    onClick={() => formik.setFieldValue("provider_search", "")}
                  />
                }
              />
            </div>
          }
        />
      }
    >
      <TagFilters
        value={values.specializationId}
        onChange={(value) => formik.setValues({ specializationId: value, provider_search: "" })}
        options={specializations.map(({ id, name }) => ({ value: id, label: t(name) }))}
      />
      {!_.isEmpty(filteredList) &&
        <div className={styles.list}>
          {_.orderBy(filteredList, ["status", "highlight_position" ], ["asc", "desc"])
            .map((provider) => {
              const { id, appointment_link } = provider
              const showing = !!_.get(showingLoaders, id)

              return (
                <ProviderCard
                  key={id}
                  provider={provider}
                  selected={providerId === id}
                  specializationId={values.specializationId}
                  onSpecializationClick={(id) => {
                    if (values.specializationId === id) {
                      formik.setValues({ specializationId: undefined, provider_search: "" })
                    } else {
                      formik.setValues({ specializationId: id, provider_search: "" })
                    }
                  }}
                  actions={
                    <>
                      {!appointment_link && <div />}
                      {appointment_link &&
                        <Button
                          color="dark"
                          variant="outline"
                          radius="lg"
                          leftIcon={<Calendar />}
                          component="a"
                          target="_blank"
                          href={appointment_link}
                        >
                          {t("appointment")}
                        </Button>
                      }
                      <Button
                        radius="lg"
                        color="dark"
                        variant="filled"
                        leftIcon={<Send />}
                        loading={showing}
                        onClick={() => showRequestCreate({ providerId: id })}
                      >
                        {t("send_message")}
                      </Button>
                    </>
                  }
                />
              )
            })}
        </div>
      }
      {_.isEmpty(filteredList) &&
        <div className="centered-content">
          <Alert
            color="gray"
            icon={<InfoCircle />}
            style={{ background: "transparent" }}
          >
            <Text color="gray">
              {t("no_providers_found_matching_your_search")}
            </Text>
          </Alert>
        </div>
      }
    </AppPage>
  )
}


export default ProviderList
