import axios from "core/axios"
import cleanDeep from "clean-deep"
import { fetchRequest, selectRequest } from "modules/requests"

const POPULATE = ["client", "request"]

export const CLIENT_TYPES = {
  person: "person",
  company: "company"
}

const getRequestId = (data) => {
  const { request } = data || {}
  return request.id
}

export const createClientRequest = async ({ name, email, text }) => {
  const data = await axios.post("/provider-clients", {
    data: cleanDeep({
      name,
      email,
      text
    })
  }, { params: { populate: POPULATE } })

  const requestId = getRequestId(data)
  const item = await fetchRequest(requestId)
  return item
}

export const updateClientRequest = async (id, values) => {
  const { provider_client } = selectRequest(id)
  await axios.put(`/provider-clients/${provider_client.id}`, {
    data: values
  }, { params: { populate: POPULATE } })
  const item = await fetchRequest(id)
  return item
}

