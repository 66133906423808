import { useMessageSockets, useMessages } from "modules/requests/messages"
import React from "react"
import styles from "./index.module.scss"
import history from "core/history"
import RequestMessage from "modules/requests/RequestMessage"
import { useRequest } from "modules/requests"
import ChatInput from "./ChatInput"
import _ from "lodash"
import AppPage from "components/AppPage"
import { selectCurrentUser, useIsProvider } from "modules/auth"
import { useSelector } from "lodash-redux"
import ChatHeader from "./ChatHeader"
import join from "clsx"
import PageFooter from "components/PageFooter"
import { ActionIcon, Text } from "@mantine/core"
import { Ban } from "tabler-icons-react"
import { useTranslation } from "react-i18next"
import ProviderActions from "./ProviderActions"
import { PageContext } from "core/pages"


function RequestChat({ requestId }) {
  const { t } = useTranslation()
  const isProvider = useIsProvider()
  const { compact } = React.useContext(PageContext)
  const currentUser = useSelector(() => selectCurrentUser())

  useMessageSockets(requestId)
  const [messages, loadingMessages] = useMessages(requestId)
  const [request, loadingRequest] = useRequest(requestId, (error) => {
    if (error) history.replace("/")
  })
  const { provider_responded } = request || {}


  // TODO: de cacat
  const [ref, setRef] = React.useState()
  React.useEffect(() => {
    if (ref) {
      ref.scrollIntoView()
    }
  }, [requestId, ref, loadingMessages])

  const loading = loadingMessages || loadingRequest
  const { blocked } = request
  const blockedMessage = isProvider
    ? t("this_user_has_been_blocked")
    : t("you_have_been_blocked_from_contacting_this_provider")

  if (!requestId) return null
  return (
    <AppPage
      flex={1}
      loading={loading}
      className={join(styles.requestChat, compact && styles.compact)}
      header={<ChatHeader request={request} compact={compact} />}
      footer={
        <>
          {!blocked && <ChatInput request={request} />}
          {blocked &&
            <PageFooter>
              <div className={styles.footer}>
                <ActionIcon color="red"><Ban></Ban></ActionIcon>
                <Text color="gray" size="sm">
                  {blockedMessage}
                </Text>
              </div>
            </PageFooter>
          }
        </>
      }
    >
      <div className={styles.content}>
        <div className={styles.messages}>
          {_.orderBy(Object.values(messages), ["id"], ["asc"])
            .map((message) => {
              const { id, user, loading } = message || {}
              const isCurrent = user?.id === currentUser?.id
              return (
                <RequestMessage
                  key={id}
                  compact={compact}
                  loading={loading}
                  requestId={requestId}
                  message={message}
                  current={isCurrent}
                />
              )
            })}
          {(!loading && !provider_responded && isProvider) && <ProviderActions request={request} />}
          {!_.isEmpty(messages) && <div ref={(node) => setRef(node)} />}
        </div>
      </div>

    </AppPage>
  )
}


export default RequestChat
