import { selectCurrentUser, selectToken } from "modules/auth"
import axios, { WS_URL } from "core/axios"
import { clearLoader, setLoader, useLoader } from "core/loaders"
import { actions } from "core/store"
import dayjs from "dayjs"
import _ from "lodash"
import { useSelector } from "lodash-redux"
import React from "react"
import { io } from "socket.io-client"
import { selectDocuments } from "modules/documents"
import { fetchRequest } from "modules/requests"
import { EMPTY_OBJECT } from "core"
import { PAGE_SIZE } from "core/data"


const MESSAGE_POPULATE = [
  "user.profile.photo",
  "user.profile",
  "user",
  "documents.file",
  "documents.message",
  "request",
  "transaction",
  "transaction.dynamic_services",
  "transaction.protected_message.protected_documents.file"
]

export const selectMessages = (requestId) => actions.get(`messages.${requestId}`, EMPTY_OBJECT)
export const fetchMessage = async (messageId) => {
  const { data: message } = await axios.get(`/messages/${messageId}`, {
    params: {
      populate: MESSAGE_POPULATE
    }
  })
  const { request, meta } = message || {}
  const { id: requestId } = request || {}
  const { key } = meta || {}

  actions.update(`messages.${requestId}`, (messages) => {
    const _messages = _.clone(messages)
    _.unset(_messages, key)
    _.set(_messages, [message.id], message)
    return _messages
  })

  return message
}


export const useMessages = (requestId) => {
  const loading = useLoader(`fetchMessages.${requestId}`)
  React.useEffect(() => {
    fetchMessages(requestId)
  }, [requestId])
  const messages = useSelector(() => selectMessages(requestId))
  return [messages, loading && _.isEmpty(messages)]
}


export const fetchMessages = async (requestId, params) => {
  try {
    setLoader(`fetchMessages.${requestId}`)
    const { data = [] } = await axios.get("/messages", {
      params: {
        populate: MESSAGE_POPULATE,
        filters: { request: requestId },
        pagination: { pageSize: PAGE_SIZE },
        sort: ["id:desc"],
        ...params
      }
    })
    const items = data.reduce((acc, message) => {
      acc[message.id] = message
      return acc
    }, {})

    actions.update(`messages.${requestId}`, items)
    return items
  } finally {
    clearLoader(`fetchMessages.${requestId}`)
  }
}


export const viewMessage = async (id) => {
  const { request, client_viewed_at, provider_viewed_at } = await axios.post(`/messages/${id}/view`)
  actions.update(`messages.${request.id}.${id}`, {
    client_viewed_at,
    provider_viewed_at
  })
}


export const createMessage = async ({ requestId, text = "", documentIds = [], type }) => {
  const messages = selectMessages(requestId)
  const key = Math.max(...Object.keys(messages), 0) + 1

  try {
    setLoader("creatingMessage")
    const meta = { type, key, documentIds }
    actions.set(`messages.${requestId}.${key}`, {
      id: key,
      text,
      user: selectCurrentUser(),
      createdAt: dayjs().toISOString(),
      updatedAt: dayjs().toISOString(),
      loading: true,
      meta,
      documents: Object.values(_.pick(selectDocuments(), documentIds))
    })

    const { data: message } = await axios.post("/messages", {
      data: _.omitBy({
        text,
        request: requestId,
        documents: documentIds,
        meta
      }, _.isNil)
    }, { params: { populate: MESSAGE_POPULATE } })

    actions.update(`messages.${requestId}`, (messages) => {
      const _messages = _.clone(messages)
      _.unset(_messages, key)
      _.set(_messages, [message.id], message)
      return _messages
    })
    return message
  } catch (error) {
    actions.update(`messages.${requestId}.${key}`, { error: error.message, loading: false })
  } finally {
    clearLoader("creatingMessage")
  }
}


export const useMessageSockets = (requestId) => {
  const token = useSelector(() => selectToken())
  const { id: userId } = useSelector(() => selectCurrentUser())

  React.useEffect(() => {
    if (token && requestId && userId) {
      const socket = io(WS_URL, {
        secure: true,
        transports: ["websocket", "polling"],
        auth: { token: token },
        query: { request: requestId, user: userId }
      })
      socket.on("connect", () => {
        socket.on(`request-${requestId}`, async ({ data, viewed, ...rest }) => {
          const id = data?.id || rest?.id || viewed?.id
          const message = await fetchMessage(id)
          if (!viewed?.id) actions.set(`requests.${requestId}.lastMessage`, message)
          const { meta } = message
          const { type } = meta || {}
          if (type === "system") await fetchRequest(requestId)
        })
      })

      return () => socket.disconnect()
    }
  }, [token, userId, requestId])
}


export const deleteMessage = (id, requestId) => actions.unset(`messages.${requestId}.${id}`)

