import React from "react"
import { ActionIcon, Text } from "@mantine/core"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import { Lock, User } from "tabler-icons-react"
import { Select } from "@mantine/core"
import _ from "lodash"
import { getRequestInfo, useRequests } from "modules/requests"


function ClientSelect({ value, readOnly, onChange = _.noop, ...props }) {
  const { t } = useTranslation()
  const [requests] = useRequests()
  const options = React.useMemo(() => Object.values(requests).map((request) => {
    const info = getRequestInfo({ request, isProvider: true })
    return {
      value: request.id,
      label: info.name,
      email: info.email
    }
  }), [requests])

  return (
    <Select
      readOnly={readOnly}
      rightSection={readOnly &&
        <ActionIcon>
          <Lock />
        </ActionIcon>
      }
      searchable
      withinPortal
      variant="filled"
      icon={<User />}
      label={t("client")}
      value={value}
      onChange={(id) => onChange(id)}
      itemComponent={ClientOption}
      data={options}
      {...props}
    />
  )
}

const ClientOption = React.forwardRef((props, ref) => {
  const { value, label, email, ...rest } = props
  return (
    <div ref={ref} className={styles.clientOption} {...rest}>
      <Text className="elipsis" size="sm" weight="bolder">
        {label}
      </Text>
      <Text size="xs">
        {email}
      </Text>
    </div>
  )
})

export default ClientSelect
