import { List, Text, Title } from "@mantine/core"
import { t } from "i18next"
import React from "react"
import styles from "./index.module.scss"
import AppCard from "components/AppCard"
import { getYearRange } from "modules/providers"
import _ from "lodash"


function EducationCard({ provider }) {
  const { profile } = provider || {}
  const { education = [] } = profile || {}

  if (_.isEmpty(education)) return null
  return (
    <AppCard name={t("education")}>
      <List
        spacing="xs"
        size="sm"
        listStyleType="none"
      >
        {education.map((item) => {
          const { id, name, description } = item
          const yearRange = getYearRange(item)

          return (
            <List.Item
              key={id}
              className={styles.educationItem}
            >
              <Title order={5}>{name}</Title>
              <Text size="sm">{description}</Text>
              <Text size="sm">{yearRange}</Text>
            </List.Item>
          )
        })}
      </List>
    </AppCard>
  )
}


export default React.memo(EducationCard)
