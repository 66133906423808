import { Badge } from "@mantine/core"
import { STATUS_COLORS } from "core"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"


function StatusBadge({ status, compact }) {
  const { t } = useTranslation()
  const color = STATUS_COLORS[status]


  return (
    <div className={styles.statusBadge}>
      <Badge
        color={color}
        size="xs"
        variant="filled"
        style={{ textTransform: "capitalize" }}
      >
        {t(status)}
      </Badge>
    </div>
  )
}


export default React.memo(StatusBadge)