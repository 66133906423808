import { Divider, Text, Title } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"


function ServicesSection({ provider }) {
  const { t } = useTranslation()
  const { id, initial_service_price = 89 } = provider
  const description = id
    ? t("pricing_description_provider", { initial_service_price })
    : t("pricing_description", { initial_service_price })
  return (
    <>
      <div />
      <Title order={4} >{t("pricing")}</Title>
      <Text size="sm">{description}</Text>
      <Divider />
      <Text size="xs" color="gray">{t("services_section_description_4")}</Text>
    </>
  )
}

export default React.memo(ServicesSection)
