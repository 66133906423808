import React from "react"
import { ActionIcon, Card, Text } from "@mantine/core"
import styles from "./index.module.scss"
import join from "clsx"
import { DownloadOff } from "tabler-icons-react"
import { useTranslation } from "react-i18next"


function EmptyDocument({ className, props }) {
  const { t } = useTranslation()


  return (
    <Card
      radius={0}
      className={join(styles.emptyDocument, className)}
      {...props}
    >
      <div className={styles.left}>
        <Text weight="bold" size="sm" lineClamp={1} color="gray">
          {t("document_no_longer_available")}
        </Text>
        <div />
      </div>
      <div className={styles.actions}>
        <ActionIcon size="sm">
          <DownloadOff />
        </ActionIcon>
      </div>
    </Card>
  )
}

export default React.memo(EmptyDocument)
