import { ActionIcon, Button, Textarea } from "@mantine/core"
import { createMessage } from "modules/requests/messages"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"
import { CreditCard, Paperclip, Send } from "tabler-icons-react"
import { useFormik } from "formik"
import { Dropzone } from "@mantine/dropzone"
import _ from "lodash"
import { createDocument } from "modules/documents"
import { setAlertError } from "core/alerts"
import { useBreakpoint } from "core/layout"
import PageFooter from "components/PageFooter"
import { showDynamicModal } from "modules/dynamics"
import { useIsProvider } from "modules/auth"
import { showModal } from "core/modals"
import DocumentModal from "modules/documents/DocumentModal"
import { clearLoader, setLoader, useLoader } from "core/loaders"


function ChatInput({ request, onClick }) {
  const { t } = useTranslation()
  const { id } = request || {}
  const loading = useLoader("createDocument")
  const isProvider = useIsProvider()
  const sm = useBreakpoint("sm")
  const dropzoneRef = React.useRef(null)
  const formik = useFormik({
    initialValues: {
      text: ""
    },
    validate: ({ text }) => {
      const errors = {}
      if (_.isEmpty(text)) errors.text = t("you_must_type_something")
      return errors
    },
    onSubmit: async ({ text }, formik) => {
      try {
        formik.setSubmitting(true)
        formik.resetForm()
        await createMessage({ text, requestId: id })
      } finally {
        formik.setSubmitting(false)
      }
    }
  })
  const { values } = formik
  const { text = "" } = values

  const size = sm ? "lg" : "xl"


  return (
    <PageFooter
      shadow="sm"
      className={styles.chatInput}
      onClick={onClick}
    >

      <div className={styles.actions}>
        {isProvider &&
          <ActionIcon
            size={size}
            onClick={() => showDynamicModal(id)}
          >
            <CreditCard />
          </ActionIcon>
        }
      </div>
      <div className={styles.input}>
        <Textarea
          size="sm"
          variant="unstyled"
          rows={1}
          maxRows={5}
          autosize
          name="text"
          value={text}
          onChange={formik.handleChange}
          placeholder={t("type_your_message")}
        />
      </div>
      <div className={styles.actions}>
        {(!text || loading) &&
          <>
            {isProvider &&
              <ActionIcon
                size={size}
                loading={loading}
                onClick={() => dropzoneRef.current()}
              >
                <Paperclip />
              </ActionIcon>
            }
            {!isProvider &&
              <ActionIcon
                size={size}
                onClick={() => showModal(DocumentModal, {
                  onSubmit: (ids) => {
                    createMessage({ requestId: id, documentIds: ids })
                  }
                })}
              >
                <Paperclip />
              </ActionIcon>
            }
          </>
        }
        {values.text && !loading &&
          <ActionIcon
            size={size}
            radius="50%"
            type="submit"
            onClick={formik.handleSubmit}
            color="primary"
            variant="filled"
          >
            <Send />
          </ActionIcon>
        }

      </div>
      {isProvider &&
        <Dropzone
          hidden
          activateOnClick={false}
          openRef={dropzoneRef}
          onDrop={async (files) => {
            const documentIds = []
            try {
              setLoader("createDocument")
              for (const file of files) {
                const document = await createDocument({ file })
                documentIds.push(document.id)
              }
            } catch (error) {
              setAlertError(error.message)
            } finally {
              clearLoader("createDocument")
            }
            await createMessage({ documentIds, requestId: id })
          }}
        />
      }
    </PageFooter>
  )
}


export default React.memo(ChatInput)
