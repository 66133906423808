import { notifications } from "@mantine/notifications"
import _ from "lodash"
import React from "react"
import i18n from "./i18n"
import { actions } from "./store"


export const setAlertError = (message, title = i18n.t("error")) => {
  notifications.show({ title, message, color: "red" })
}

export const setAlertSuccess = (message, title = i18n.t("success")) => {
  notifications.show({ title, message, color: "green" })
}

export const setAlertInfo = (message, title = i18n.t("info")) => {
  notifications.show({ title, message, color: "blue" })
}

export const useAlerts = () => {
  React.useEffect(() => {
    window.addEventListener("error", handleError)
    window.addEventListener("unhandledrejection", handleError)
    return () => {
      window.removeEventListener("error", handleError)
      window.removeEventListener("unhandledrejection", handleError)
    }
  }, [])

}

const EMPTY_ARRAY = []
const DEBOUNCE_TIME = 2000 // time between identical alerts

const handleError = (event) => {
  const message = (
    event?.reason?.message ||
    i18n.t("unknown_error")
  )

  const alerts = actions.get("alerts", EMPTY_ARRAY)
  if (!alerts.includes(message)) {
    setAlertError(message)
    actions.update("alerts", (alerts = []) => _.uniq([...alerts, message]))
    setTimeout(() => {
      actions.update("alerts", (alerts = []) => _.without(alerts, message))
    }, DEBOUNCE_TIME)
  }
}
