import React from "react"
import { Button, NumberInput, Text, Textarea, TextInput } from "@mantine/core"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import { File, DeviceFloppy } from "tabler-icons-react"
import { useFormik } from "formik"
import AppModal from "components/AppModal"
import { hideModal } from "core/modals"
import _ from "lodash"
import { createTimesheet, getTimesheetInfo, updateTimesheet, useTimesheet } from "modules/timesheets"
import dayjs from "dayjs"
import * as yup from "yup"
import { DateInput } from "@mantine/dates"

const INITIAL_VALUES = {
  name: "",
  description: "",
  requestId: "",
  registerAt: dayjs().startOf("day").toISOString(),
  startAt: "",
  endAt: "",
  hours: 0,
  minutes: 0,
  projectId: ""
}


function TimesheetModal({ timesheetId, projectId, onSubmit = _.noop }) {
  const { t } = useTranslation()
  const [timesheet] = useTimesheet(timesheetId)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: _.pick({
      ...INITIAL_VALUES,
      projectId,
      ...timesheet,
      ...getTimesheetInfo(timesheet)
    }, Object.keys(INITIAL_VALUES)),
    validationSchema: yup.object({
      name: yup.string().required(t("field_is_required"))
    }),
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        let { started, hours, minutes, ...rest } = values
        rest.startAt = dayjs()
          .subtract(Number(hours), "hours")
          .subtract(Number(minutes), "minutes")
          .toISOString()

        rest.endAt = dayjs().toISOString()
        if (started) {
          rest.endAt = null
        }


        let item
        if (timesheetId) {
          item = await updateTimesheet(timesheetId, rest)
        } else {
          item = await createTimesheet(rest)
        }

        await onSubmit(item)
        hideModal()
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { values, errors, touched } = formik
  const { name, hours = 0, minutes = 0, registerAt } = values

  return (
    <AppModal
      size="sm"
      className={styles.taskModal}
      name={timesheetId ? t("edit_time") : t("new_time")}
      footer={
        <>
          <Button
            radius="lg"
            color="dark"
            variant="filled"
            leftIcon={<DeviceFloppy />}
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            {t("save")}
          </Button>
        </>
      }
    >
      <TextInput
        variant="filled"
        label={t("name")}
        name="name"
        value={name}
        onChange={formik.handleChange}
        icon={<File />}
        error={touched.name && errors.name}
      />
      <div className={styles.row}>
        <DateInput
          variant="filled"
          label={t("date")}
          value={registerAt && dayjs(registerAt).toDate()}
          onChange={(value) => formik.setFieldValue("registerAt", value)}
        />
        <div className={styles.time}>
          <NumberInput
            name="hours"
            className={styles.hours}
            label={t("hours")}
            hideControls
            variant="filled"
            value={hours}
            onChange={(value) => {
              formik.setFieldValue("hours", value)
              formik.setFieldValue("started", false)
            }}
          />
          <Text className={styles.center} color="gray">
            :
          </Text>
          <NumberInput
            name="minutes"
            className={styles.minutes}
            label={t("minutes")}
            hideControls
            variant="filled"
            min={0}
            max={59}
            value={minutes}
            onChange={(value) => {
              formik.setFieldValue("minutes", value)
              formik.setFieldValue("started", false)
            }}
          />
        </div>
      </div>
    </AppModal>
  )
}

export default TimesheetModal
