import { ActionIcon, Button, NumberInput, Text, TextInput, Title } from "@mantine/core"
import AppCard from "components/AppCard"
import PageFooter from "components/PageFooter"
import { setAlertSuccess } from "core/alerts"
import { deleteAccount, updateUser, useIsProvider } from "modules/auth"
import { useFormik } from "formik"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { DeviceFloppy, Lock, Mail, User } from "tabler-icons-react"
import styles from "./index.module.scss"
import set from "lodash/fp/set"
import unset from "lodash/fp/unset"
import { modals } from "@mantine/modals"


function InfoSection({ name, user, setFooters }) {
  const { t } = useTranslation()
  const isProvider = useIsProvider()
  const { profile, email, initial_service_price } = user
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      initial_service_price,
      ...profile
    },
    onSubmit: async ({ initial_service_price, ...profile }, formik) => {
      try {
        formik.setSubmitting(true)
        await updateUser({ initial_service_price, profile })
        setAlertSuccess(t("profile_updated"))
      } finally {
        formik.setSubmitting(false)
      }
    }
  })
  const { errors, values, touched } = formik

  React.useEffect(() => {
    if (formik.dirty) {
      setFooters((footers) => set(name, (
        <PageFooter>
          <Button
            radius="lg"
            variant="outline"
            color="dark"
            onClick={formik.resetForm}
          >
            {t("cancel")}
          </Button>
          <Button
            leftIcon={<DeviceFloppy />}
            loading={formik.isSubmitting}
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
            type="submit"
            color="dark"
            radius="lg"
          >
            {t("save")}
          </Button>
        </PageFooter>
      ), footers))
    } else {
      setFooters((footers) => unset(name, footers))
    }
  }, [formik.dirty])


  return (
    <AppCard
      className={styles.infoSection}
      name={t("info")}
      component="form"
      autoComplete="off"
      actions={!isProvider &&
        <Button
          radius="lg"
          variant="outline"
          color="red"
          className={styles.button}
          onClick={() => {
            modals.openConfirmModal({
              confirmProps: {
                radius: "lg",
                variant: "filled",
                color: "red"
              },
              cancelProps: {
                radius: "lg",
                variant: "outline",
                color: "dark"
              },
              title: <Title order={5}>{t("delete_account")}</Title>,
              children: (
                <Text size="sm">
                  {t("are_you_sure_you_want_to_delete_your_account")}
                </Text>
              ),
              labels: { confirm: t("confirm"), cancel: t("cancel") },
              onConfirm: () => deleteAccount()
            })
          }}
        >
          {t("delete_account")}
        </Button>
      }
    >
      <TextInput
        radius={0}
        name="name"
        variant="filled"
        icon={<User />}
        label={t("name")}
        value={values.name}
        onChange={formik.handleChange}
        error={touched.name && errors.name}
      />
      <div className={styles.row}>
        <TextInput
          radius={0}
          variant="filled"
          icon={<Mail />}
          label={t("email")}
          value={email}
          readOnly
          rightSection={<ActionIcon><Lock /></ActionIcon>}
        />
        <div />
      </div>
      {isProvider &&
        <NumberInput
          radius={0}
          variant="filled"
          precision={0}
          min={0}
          style={{ width: "10rem" }}
          label={t("price_currency")}
          name="initial_service_price"
          value={initial_service_price}
          onChange={(value) => formik.setFieldValue("initial_service_price", value)}
          error={touched.initial_service_price && errors.initial_service_price}
        />
      }
    </AppCard>
  )
}

export default React.memo(InfoSection)
