import { Input, Loader, Modal } from "@mantine/core"
import { hideModal } from "core/modals"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"


function LoaderModal({ message }) {
  const { t } = useTranslation()
  return (
    <Modal
      size="xs"
      radius="md"
      opened
      centered
      onClose={hideModal}
      withCloseButton={false}
      className={styles.loaderModal}
      padding="0.5rem 1rem"
    >
      <div className={styles.content}>
        <Loader size="sm" />
        <Input.Wrapper
          label={t("loading")}
          description={t(message)}
        />
      </div>
    </Modal>
  )
}

export default LoaderModal
