import i18n from "i18next"
import _ from "lodash"
import { initReactI18next } from "react-i18next"
import translationRo from "locales/ro/translation.json"
import translationEn from "locales/en/translation.json"
import "dayjs/locale/ro"
import dayjs from "dayjs"
import { actions } from "./store"
import { selectCurrentUser, updateUser } from "modules/auth"
import qs from "qs"

export const LANGUAGES = ["ro", "en"]
export const DEFAULT_LANG = _.first(LANGUAGES)


export const initLocale = async () => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const user = selectCurrentUser()

  let lang = params.language || user.lang
  if (!LANGUAGES.includes(lang)) lang = actions.get("language", DEFAULT_LANG)
  if (user.lang !== lang) await updateUser({ lang })

  dayjs.locale(lang)
  actions.set("language", lang)
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: lang,
      returnEmptyString: false,
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      resources: {
        ro: {
          translation: translationRo
        },
        en: {
          translation: translationEn
        }
      },
      parseMissingKeyHandler: (value) => _.capitalize(value.split("_").join(" "))
    })
}

export const changeLanguage = (lang) => {
  updateUser({ lang })
  dayjs.locale(lang)
  i18n.changeLanguage(lang)
  actions.set("language", lang)
}

export default i18n
