import dayjs from "dayjs"
import _ from "lodash"
import React from "react"
import { AlertCircle, AlertTriangle, Ban, Check, Folder } from "tabler-icons-react"


export const EMPTY_OBJECT = {}
export const EMPTY_ARRAY = []

export const includesLowerCase = (value = "", search = "") => {
  const items = search.split(" ")
  return items.some((item) => normalizeStr(value).includes(normalizeStr(item)))

}

const normalizeStr = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()


export const useMounted = (effect = _.noop, timeout = 0) => {
  const [mounted, setMounted] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setMounted(true), timeout)
    return effect()
  }, [])
  return mounted
}


export const useFirst = (list = [], callback = _.noop, reactivate) => {
  const [done, setDone] = React.useState()
  React.useEffect(() => {
    if ((!done || reactivate) && !_.isEmpty(list)) {
      const item = _.first(list)
      callback(item)
      setDone(true)
    }
  }, [_.isEmpty(list), reactivate])
}

export const formatCreatedAt = (createdAt) => {
  if (dayjs().diff(createdAt, "day") > 1) {
    return dayjs(createdAt).format("L")
  } else {
    return dayjs(createdAt).fromNow()
  }

}

export const STATUS_COLORS = {
  awaiting_payment: "red",
  active: "green",
  pending: "red",
  closed: "dark",
  deleted: "red",
  has_member_activity: "blue",
  has_user_activity: "blue",
  draft: "gray",
  in_progress: "blue",
  blocked: "red",
  busy: "red",
  disabled: "gray",
  payed: "green",
  online: "green",
  has_activity: "blue",
  enabledCRM: "yellow"
}


export const STATUS_ICONS = {
  blocked: Ban,
  enabledCRM: Folder,
  payed: Check,
  pending: AlertCircle
}
