import { Button, Divider, PasswordInput, Text, TextInput } from "@mantine/core"
import { facebookLogin, googleLogin, emailLogin } from "modules/auth"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { BrandFacebook, Key, User } from "tabler-icons-react"
import _ from "lodash"
import styles from "./index.module.scss"
import AppCard from "components/AppCard"
import { ReactComponent as GoogleIcon } from "./google.svg"
import { actions } from "core/store"
import AppModal from "components/AppModal"
import { showModal } from "core/modals"
import RegisterModal from "../RegisterModal"
import RecoverModal from "../RecoverModal"
import { useSelector } from "lodash-redux"
import UserLinks from "../UserLinks"


const loginActions = actions.create("layout.login")
const updateActions = _.debounce((values) => loginActions.update(values), 100, {
  leading: false,
  trailing: true
})


function LoginModal() {
  let disableAuthProviders = useSelector(() => actions.get("disableAuthProviders"))
  disableAuthProviders = false

  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: { // is set when registering
      email: actions.get("registerEmail") || loginActions.get("email") || "",
      password: ""
    },
    validate: (values) => updateActions(_.omit(values, ["password"])),
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        await emailLogin(values)
      } catch (error) {
        const { data, message } = error
        if (_.isEmpty(data)) {
          formik.setErrors({ email: true, password: message })
        } else {
          const { identifier, password } = data
          formik.setErrors({ email: identifier, password })
        }
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { values, errors } = formik
  const { email, password } = values
  const initialValues = _.omit(values, ["password"])

  return (
    <AppModal
      size="sm"
      className={styles.loginModal}
      name={t("login")}
    >
      <TextInput
        radius={0}
        variant="filled"
        label={t("email")}
        name="email"
        value={email}
        onChange={formik.handleChange}
        icon={<User />}
        error={errors.email}
      />
      <PasswordInput
        radius={0}
        variant="filled"
        label={t("password")}
        name="password"
        value={password}
        onChange={formik.handleChange}
        icon={<Key />}
        error={errors.password}
      />
      <Button
        variant="white"
        compact
        size="xs"
        style={{ alignSelf: "flex-end" }}
        onClick={() => showModal(RecoverModal, { initialValues })}
      >
        {t("forgot_password")}
      </Button>
      <Button
        radius="lg"
        color="dark"
        type="submit"
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
      >
        {t("login")}
      </Button>
      <Button
        radius="lg"
        color="dark"
        variant="outline"
        onClick={() => showModal(RegisterModal, { initialValues })}
      >
        {t("register")}
      </Button>

      {!disableAuthProviders &&
        <>
          <Divider label={t("or")} labelPosition="center" />
          <>
            <Button
              variant="outline"
              radius="lg"
              color="dark"
              leftIcon={<GoogleIcon />}
              onClick={() => googleLogin()}
            >
              <span style={{ opacity: .75 }}>
                {t("connect_with_google")}
              </span>
            </Button>
            <Button
              color="blue"
              radius="lg"
              leftIcon={<BrandFacebook />}
              onClick={() => facebookLogin()}
            >
              {t("connect_with_facebook")}
            </Button>
          </>
        </>
      }
      <Divider labelPosition="center" />
      <UserLinks />
    </AppModal>
  )
}

export default LoginModal
