import { Text } from "@mantine/core"
import { t } from "i18next"
import React from "react"
import styles from "./index.module.scss"


function UserLinks({ size = "xs", color = "primary", weight }) {
  return (
    <div className={styles.userLinks}>
      <Text
        size={size}
        weight={weight}
        color={color}
        underline
        component="a"
        target="_blank"
        href="https://avochat.net/politica-de-confidentialitate/"
      >
        {t("terms_and_conditions")}
      </Text>
      <Text
        size={size}
        weight={weight}
        color={color}
        underline
        component="a"
        target="_blank"
        href="https://www.avochat.net/politica-de-confidentialitate"
      >
        {t("confidentiality_agreement")}
      </Text>
      <Text
        size={size}
        weight={weight}
        color={color}
        underline
        component="a"
        target="_blank"
        href=" https://www.avochat.net/contact"
      >
        {t("contact")}
      </Text>
    </div>

  )
}

export default React.memo(UserLinks)