
import axios from "core/axios"
import { clearLoader, selectLoader, setLoader, useLoader } from "core/loaders"
import { actions } from "core/store"
import _ from "lodash"
import { useSelector } from "lodash-redux"
import React from "react"
import cleanDeep from "clean-deep"
import { useData } from "core/data"
import { getUserInfo, selectToken } from "modules/auth"
import { createDocument } from "modules/documents"
import LoginModal from "modules/auth/LoginModal"
import { showModal } from "core/modals"
import RequestModal from "./RequestModal"
import { selectProvider } from "modules/providers"
import { modals } from "@mantine/modals"
import { Text, Title } from "@mantine/core"
import i18n from "core/i18n"
import { Clock, Switch2 } from "tabler-icons-react"
import pageActions from "core/pages"
import { EMPTY_OBJECT } from "core"


const POPULATE = [
  "services",
  "user.provider_client",
  "user.profile",
  "user",
  "members.specializations",
  "members.profile",
  "members.profile.photo",
  "documents.file",
  "documents.message",
  "provider_client",
  "lastMessage.user",
  "lastMessage.profile.photo",
  "lastMessage.profile"
]


export const requestActions = actions.create("layout.requestActions")


export const selectRequest = (id) => actions.get(`requests.${id}`, EMPTY_OBJECT)
export const selectRequests = () => actions.get("requests", EMPTY_OBJECT)

export const useRequests = (params, deps) => {
  return useData("requests", fetchRequests, params, deps)
}

const fetchRequests = async (params) => {
  const token = selectToken()
  if (token) {
    const { populate, ...query } = params || {}
    const { data = [] } = await axios.get("/requests", { // TODO: when adding pagination sort should be made using api
      params: cleanDeep({
        populate: populate || POPULATE,
        ...query
      })
    })

    const items = data.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})

    actions.update("requests", items)
    return items
  } else {
    return EMPTY_OBJECT
  }
}


export const useRequest = (id, callback) => {
  const loading = useLoader(`fetchRequest.${id}`)
  const request = useSelector(() => selectRequest(id))
  React.useEffect(() => {
    if (id && !selectLoader(`fetchRequest.${id}`)) {
      fetchRequest(id, callback)
    }
  }, [id])
  return [request, loading && _.isEmpty(request)]
}
export const fetchRequest = async (id, callback = _.noop) => {
  try {
    setLoader(`fetchRequest.${id}`)
    const { data: item } = await axios.get(`/requests/${id}`, {
      params: { populate: POPULATE }
    })
    await callback(undefined, item)
    actions.set(`requests.${item.id}`, item)
    return item
  } catch (error) {
    await callback(error)
    throw error
  } finally {
    clearLoader(`fetchRequest.${id}`)
  }
}


export const createRequest = async (values, callback) => {
  try {
    setLoader("creating_request")
    const { text, files, providerId } = values
    const request = await axios.post("/requests", {
      data: cleanDeep({
        members: [providerId].filter(Boolean),
        text
      })
    }, {
      params: {
        populate: POPULATE
      }
    })

    setLoader("uploading_documents")
    if (!_.isEmpty(files)) {
      for (const file of files) {
        await createDocument({
          requestId: request.id,
          messageId: request.lastMessage.id,
          file
        })
      }
    }
    actions.set(`requests.${request.id}`, request)
    return request
  } catch (error) {
    await callback(error)
    throw error
  } finally {
    clearLoader("uploading_documents")
    clearLoader("creating_request")
  }
}
export const assignRequest = async (id) => {
  try {
    setLoader(`assignRequest.${id}`)
    const item = await axios.post(`/request/${id}/assign`, {
      params: {
        populate: POPULATE
      }
    })
    actions.set(`requests.${item.id}`, item)
    return item
  } finally {
    clearLoader(`assignRequest.${id}`)
  }
}


export const blockRequest = async (id, callback = _.noop) => {
  try {
    setLoader(`blockRequest.${id}`)
    const item = await axios.post(`/request/${id}/block`, {
      params: {
        populate: POPULATE
      }
    })
    await callback()
    actions.set(`requests.${id}`, item)
  } catch (error) {
    await callback(error)
    throw error
  } finally {
    clearLoader(`blockRequest.${id}`)
  }
}

export const updateRequest = async (id, values) => {
  const { status, providerId } = values
  const { data: request } = await axios.put(`/requests/${id}`, {
    data: cleanDeep({
      status,
      members: [providerId]
    })
  }, { params: { populate: POPULATE } })
  actions.set(`requests.${request.id}`, request)
  return request
}


export const redirectToPaymentUrl = (payment_url) => {
  setLoader("redirecting_to_payment_provider")
  setTimeout(() => {
    clearLoader("redirecting_to_payment_provider")
    setTimeout(() => window.open(payment_url, "_self"), 10)
  }, 500)
}


export const getRequestInfo = ({ isProvider, request }) => {
  const { user, members } = request || {}
  const [provider] = members || []
  const otherUser = isProvider ? user : provider
  const { photoUrl, name, email, description, initials } = getUserInfo(otherUser)
  return {
    name,
    photoUrl,
    description,
    initials,
    email
  }
}


export const showRequestCreate = async (params) => {
  const token = selectToken()
  if (!token) {
    showModal(LoginModal)
    requestActions.update({ ...params, pendingLogin: true })
  } else {
    const { providerId } = params || {}
    if (!providerId) {
      showModal(RequestModal, params)
    } else {
      try {
        setLoader(`showRequestCreate.${providerId}`)
        let request = findProviderRequest(providerId)
        if (!request) await fetchRequests({ filters: { members: { id: providerId } } })
        request = findProviderRequest(providerId)
        if (request) {
          pageActions
            .load("RequestChat")
            .set({ requestId: request.id })
            .replace()
        } else {
          const { status } = selectProvider(providerId)
          if (status === "busy") {
            modals.openConfirmModal({
              confirmProps: {
                radius: "lg",
                variant: "filled",
                color: "red",
                leftIcon: <Clock />
              },
              cancelProps: {
                radius: "lg",
                variant: "outline",
                color: "dark",
                leftIcon: <Switch2 />
              },
              title: <Title order={5}>{i18n.t("provider_is_busy")}</Title>,
              children: (
                <Text size="sm">
                  {i18n.t("the_selected_provider_has_many_pending_requests")}
                </Text>
              ),
              labels: {
                confirm: i18n.t("continue_anyways"),
                cancel: i18n.t("another_provider")
              },
              onConfirm: () => showModal(RequestModal, params)
            })

          } else {
            showModal(RequestModal, params)
          }

        }
      } finally {
        clearLoader(`showRequestCreate.${providerId}`)
      }
    }
  }

}

const findProviderRequest = (providerId) => {
  let requests = selectRequests()
  const request = _.find(requests, ({ members }) => _.find(members, { id: providerId }))
  return request
}