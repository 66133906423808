import { applyMiddleware, createStore, createActions } from "lodash-redux"
import { composeWithDevTools } from "redux-devtools-extension"
import _ from "lodash"
import * as idb from "idb-keyval"


const STATE_KEY = "app_state"
const PERSISTENT_PATHS = [
  "auth",
  "language",
  "registerEmail",
  "layout",
  "disableAuthProviders"
]

const store = createStore(composeWithDevTools(
  applyMiddleware(store => next => action => {
    return next(action)
  })
))

export const actions = createActions(store)


export const initStore = async () => {
  const state = await idb.get(STATE_KEY) || {}
  actions.set(state)
  store.subscribe(saveState)
}

const saveState = _.throttle(async () => {
  try {
    const state = store.getState()
    await idb.set(STATE_KEY, _.pick(state, PERSISTENT_PATHS))
  } catch (error) {
    console.error(error)
  }
}, 250, { trailing: true })


export default store
