import axios from "core/axios"
import { actions } from "core/store"
import cleanDeep from "clean-deep"
import { useData, useItem } from "core/data"
import dayjs from "dayjs"
import { EMPTY_OBJECT } from "core"
import * as XLSX from "xlsx"
import _ from "lodash"


const POPULATE = [
  "project",
  "task",
  "events"
]


export const TIME_TYPES = {
  START: "START",
  END: "END"
}

export const selectTimesheets = () => actions.get("timesheets", EMPTY_OBJECT)
export const mapTimesheet = (data) => {
  const { project = {}, task = {}, events = [], ...rest } = data || {}
  const [event = {}] = events
  const { start_date, stop_date } = event

  return {
    ...rest,
    projectId: project.id,
    taskId: task.id,
    startAt: start_date && dayjs(start_date).toISOString(),
    endAt: stop_date && dayjs(stop_date).toISOString()
  }
}

export const useTimesheets = (params = {}, deps = []) => {
  return useData("timesheets", fetchTimesheets, params, deps)
}
const fetchTimesheets = async (params) => {
  const { data = [] } = await axios.get("/timesheets", {
    params: cleanDeep({
      ...params,
      populate: POPULATE
    })
  })

  const items = data.reduce((acc, item) => {
    acc[item.id] = mapTimesheet(item)
    return acc
  }, {})

  actions.update("timesheets", items)
  return items
}

export const useTimesheet = (id, callback) => {
  return useItem(id, "timesheets", fetchTimesheet, callback)
}
const fetchTimesheet = async (id) => {
  const { data } = await axios.get(`/timesheets/${id}`, {
    params: { populate: POPULATE }
  })

  const item = mapTimesheet(data)
  actions.set(`timesheets.${item.id}`, item)
  return item
}

export const createTimesheet = async (values) => {
  const { startAt, endAt, projectId, taskId, ...rest } = values
  const { data } = await axios.post("/timesheets", {
    data: cleanDeep({
      ...rest,
      project: projectId,
      task: taskId,
      events: [{ start_date: startAt, stop_date: endAt }]
    })
  }, { params: { populate: POPULATE } })

  const item = mapTimesheet(data)
  actions.set(`timesheets.${item.id}`, item)

  return item
}

export const updateTimesheet = async (id, values) => {
  const { startAt, endAt, projectId, taskId, ...rest } = values
  const { data } = await axios.put(`/timesheets/${id}`, {
    data: {
      ...rest,
      project: projectId,
      task: taskId,
      events: [{ start_date: startAt, stop_date: endAt }]
    }
  }, { params: { populate: POPULATE } })

  const item = mapTimesheet(data)
  actions.set(`timesheets.${id}`, item)
  return item
}


export const getTimesheetInfo = ({ startAt, endAt }) => {
  const duration = dayjs.duration(dayjs(endAt).diff(dayjs(startAt)))
  const hours = duration.hours()
  const minutes = duration.minutes()

  let started
  if (startAt && !endAt) started = true

  return {
    started,
    hours,
    minutes
  }
}

export const generateReport = ({ timesheets = {}, project = {}, t }) => {
  const { name, provider_client = {} } = project
  const { name: clientName } = provider_client

  const ws = XLSX.utils.json_to_sheet(Object.values(timesheets).map((timesheet) => {
    const { name, description, registerAt } = timesheet
    const { hours, minutes } = getTimesheetInfo(timesheet)
    return {
      name,
      description,
      date: dayjs(registerAt).format("L"),
      hours: _.round(hours + minutes / 60, 1).toFixed(1)
    }
  }))

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, t("report"))
  XLSX.writeFile(wb, `${t("activity_report")}_${name}_${clientName}.xlsx`)
}

export const actionsCreate = actions.create("layout.timesheetCreate")