import { Text, Textarea } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"


function FormSection({ formik }) {
  const { t } = useTranslation()
  const { touched, errors, values } = formik
  const { text = "" } = values

  return (
    <>
      <Text size="sm">
        {t("form_section_description")}
      </Text>
      <Textarea
        variant="filled"
        name="text"
        placeholder={t("type_your_message")}
        value={text}
        onChange={formik.handleChange}
        maxLength={2000}
        minRows={7}
        className={styles.textarea}
        error={touched.text && errors.text}
      />
    </>

  )
}

export default FormSection

