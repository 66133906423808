import { ActionIcon, Button, Input, NumberInput, Text, Textarea, TextInput } from "@mantine/core"
import { hideModal } from "core/modals"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { Send, Trash } from "tabler-icons-react"
import styles from "./index.module.scss"
import * as yup from "yup"
import AppModal from "components/AppModal"
import FileItem from "modules/documents/FileItem"
import _ from "lodash"
import { Dropzone } from "@mantine/dropzone"
import { useSelector } from "lodash-redux"
import { selectCurrentUser } from "modules/auth"


function DynamicModal({ onSubmit, initialValues }) {
  const { t } = useTranslation()
  const { initial_service_price = 89 } = useSelector(() => selectCurrentUser())
  const dropzoneRef = React.useRef(null)
  const formik = useFormik({
    initialValues: {
      description: "",
      files: [],
      price: initial_service_price,
      ...initialValues
    },
    validationSchema: yup.object({
      price: yup.number().required(t("field_is_required"))
    }),
    validate: (values) => {
      const errors = {}
      const { price } = values

      if (price < 50) errors.price = t("dynamic_min_price_is_required", { min: 50 })
      return errors
    },
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        await onSubmit(values)
        hideModal()
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { values, touched, errors } = formik
  const { description, price } = values

  return (
    <AppModal
      className={styles.dynamicModal}
      name={t("dynamic_service")}
      footer={
        <Button
          leftIcon={<Send />}
          radius="lg"
          color="dark"
          loading={formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          {t("send")}
        </Button>
      }
    >
      <Textarea
        description={t("this_message_will_be_revealed_after_payment")}
        variant="filled"
        minRows={3}
        maxRows={5}
        autosize
        label={t("protected_message")}
        name="description"
        value={description}
        onChange={formik.handleChange}
        error={touched.description && errors.description}
      />
      <Input.Wrapper
        error={touched.files && errors.files}
      >
        <Dropzone
          openRef={dropzoneRef}
          maxSize={10 * 1024 * 1024}
          multiple
          onDrop={(files) => {
            formik.setFieldValue("files", [
              ...values.files,
              ...files
            ])
          }}
        >
          <div className={styles.dropzone}>
            <Text weight="bold" size="sm">
              {t("drag_and_drop")}
              {" "}
              <Text c="blue" span inherit>
                {t("or_click_here")}
              </Text>
              {" "}
              {t("to_upload_the_files")}
            </Text>
            <Text size="xs" color="gray">
              {t("dynamic_files_description")}
            </Text>
          </div>
        </Dropzone>
        {!_.isEmpty(values.files) &&
          <div className={styles.files}>
            {Object.values(values.files).map((file, index) => (
              <FileItem
                key={index}
                file={file}
                actions={
                  <ActionIcon
                    size="sm"
                    onClick={() => {
                      formik.setTouched("files", true)
                      formik.setFieldValue("files", _.pull(values.files, file))
                    }}
                  >
                    <Trash />
                  </ActionIcon>
                }
              />
            ))}
          </div>
        }
      </Input.Wrapper>
      <NumberInput
        radius={0}
        variant="filled"
        precision={0}
        min={0}
        style={{ width: "10rem" }}
        label={t("price_currency")}
        name="price"
        value={price}
        onChange={(value) => formik.setFieldValue("price", value)}
        error={touched.price && errors.price}
      />
    </AppModal>
  )
}

export default React.memo(DynamicModal)
