import { ActionIcon, Text } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import clsx from "clsx"
import { MapPin } from "tabler-icons-react"


function IconText({ className, icon, value, ...props }) {
  if (!value) return null
  return (
    <div className={clsx(styles.iconText, className)}>
      <ActionIcon size="1em" className={styles.icon}>
        {icon || <MapPin />}
      </ActionIcon>
      <Text
        span
        size="sm"
        color="gray"
        {...props}
      >
        {value}
      </Text>
    </div>

  )
}


export default React.memo(IconText)
