import { Card, Text, Title } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import join from "clsx"
import { PageContext } from "core/pages"


function AppCard({
  className,
  classNames = {},
  name,
  description,
  children,
  actions,
  onClick,
  ...props
}) {
  const { compact } = React.useContext(PageContext)
  return (
    <Card
      shadow="xs"
      radius={compact ? 0 : "md"}
      onClick={onClick}
      className={join(
        styles.appCard,
        compact && styles.compact,
        onClick && styles.clickable,
        className
      )}
      {...props}
    >
      {(name || description) &&
        <div className={styles.header}>
          {name && <Title order={4}>{name}</Title>}
          {description && <Text size="sm">{description}</Text>}
        </div>
      }
      {children &&
        <div className={join(styles.content, classNames.content)}>
          {children}
        </div>
      }
      {actions &&
        <div className={styles.actions} onClick={(event) => event.stopPropagation()}>
          {actions}
        </div>
      }
    </Card>
  )
}


export default React.memo(AppCard)
