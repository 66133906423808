import { useBreakpointListener } from "core/layout"
import AppLayout from "components/AppLayout"
import { ModalDispatcher } from "core/modals"
import React from "react"
import { Route } from "react-router"
import { Switch } from "react-router-dom"
import { useAlerts } from "core/alerts"
import { useLoaderModal } from "core/loaders"
import { useIsAuthenticated, useIsProvider, usePushNotifications } from "modules/auth"
import PageDispatcher from "core/pages/PageDispatcher"
import { Redirect } from "react-router-dom"

// TODO
// requestList page should have a call to action
/*


  login to send message pe popup login
  alerte in colt dreapta desktop
  alerte jos pe mobil
  click pe chip selectata -> deselect

  poza si text ->  go to profile
  card proiect -> go to profile

  sa vada ca se paote selecta: checkbox - DONE
  butoanele de uplaod si send sa fie doar in functie de selectie - DONE
  default -> upload - DONE
  daca a selectat -> send - DONE
  daca depaseste quota -> plateste - DONE

  tesrms and conditions -> cand uploadeaza, sub fisierul uplaodat - mesaj - TODO

  de pus searchul doar daca sunt mai mult de 5 inregistrari - NO
  pe chat -> status ca cele vechi cum ca este blocat utilizatorul - DONE

  cei blocati sa fie sortati la sfarsitul listei - DONE
  bifa albastra pentru pending

  de marit fonturile la usericonul de proiect 12 bold userul - DONE

  de fixat property of undefined pe client - DONE
  de redus paddingul la liste - DONE

*/

function App() {
  const isProvider = useIsProvider()
  const authenticated = useIsAuthenticated()
  useAlerts()
  useBreakpointListener()
  usePushNotifications()
  useLoaderModal([
    "deleting_account",
    "reloading",
    "creating_request",
    "uploading_documents",
    "redirecting_to_payment_provider"
  ])


  return (
    <AppLayout>
      <Switch>
        <Route path="/page/:name" exact component={PageDispatcher} />
        {(isProvider || authenticated) && <Redirect to="/page/RequestList" />}
        <Redirect to="/page/ProviderList" />
      </Switch>
      <ModalDispatcher />
    </AppLayout>
  )
}


export default App