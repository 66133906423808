import { ActionIcon } from "@mantine/core"
import React from "react"
import logoUrl from "logo.png"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import join from "clsx"


function Logo({ className }) {
  const { t } = useTranslation()

  return (

    <div className={join(styles.logo, className)}>
      <ActionIcon
        color="blue"
        variant="light"
        size="lg"
      >
        <img src={logoUrl} alt={t("logo")} />
      </ActionIcon>
    </div>

  )
}

export default React.memo(Logo)
