import { ActionIcon, CloseButton, Loader, Text, TextInput, Tooltip } from "@mantine/core"
import { selectLoaders } from "core/loaders"
import { useFormik } from "formik"
import _ from "lodash"
import { useSelector } from "lodash-redux"
import React from "react"
import { useTranslation } from "react-i18next"
import { Paperclip, Scale, Search, Trash } from "tabler-icons-react"
import {
  deleteDocument,
  useDocuments
} from ".."
import FileItem from "../FileItem"
import styles from "./index.module.scss"
import { includesLowerCase } from "core"
import AppPage from "components/AppPage"
import PageHeader from "components/PageHeader"
import { updateValues } from "core/pages"


function DocumentList({ document_search = "" }) {
  const { t } = useTranslation()
  const deletingLoaders = useSelector(() => selectLoaders("deleteDocument"))
  const [documents, loading] = useDocuments()

  const formik = useFormik({
    initialValues: {
      document_search
    },
    validate: (values) => updateValues(values)
  })

  const { values } = formik
  const documentList = Object.values(documents)
    .filter(({ file }) => includesLowerCase(file.name, document_search))

  return (
    <AppPage
      className={styles.documentList}
      header={
        <PageHeader
          icon={<Paperclip />}
          name={t("my_documents")}
          toolbar={
            <TextInput
              className={styles.search}
              name="document_search"
              variant="filled"
              icon={<Search />}
              placeholder={t("search_for_documents")}
              value={values.document_search}
              onChange={formik.handleChange}
              rightSection={values.document_search && (
                <CloseButton
                  onClick={() => formik.setFieldValue("document_search", "")}
                />
              )}
            />
          }
        />
      }
    >
      <form className={styles.content}>
        {_.isEmpty(documentList) && !loading &&
          <div className="centered-content">
            <Text color="gray">{t("no_items_available")}</Text>
          </div>
        }
        {_.isEmpty(documentList) && loading &&
          <div className="centered-content">
            <Loader />
          </div>
        }
        {!_.isEmpty(documentList) &&
          <div className={styles.files}>
            {_.orderBy(documentList, "id", "desc")
              .map((doc, index) => {
                const { id, file, ignore_upload_limit } = doc
                const deleting = Boolean(deletingLoaders[id])

                return (
                  <FileItem
                    key={index}
                    radius="sm"
                    file={file}
                    highlight={document_search}
                    color="white"
                    actions={
                      <>
                        {!ignore_upload_limit &&
                          <Tooltip label={t("delete_document")} withinPortal>
                            <ActionIcon
                              size="sm"
                              loading={deleting}
                              onClick={async (event) => {
                                event.stopPropagation()
                                await deleteDocument(id)
                              }}
                            >
                              <Trash />
                            </ActionIcon>
                          </Tooltip>
                        }
                        {ignore_upload_limit &&
                          <Tooltip label={t("provider_document")} withinPortal>
                            <ActionIcon
                              size="sm"
                            >
                              <Scale />
                            </ActionIcon>
                          </Tooltip>
                        }
                      </>
                    }
                  />
                )
              })}
          </div>
        }
      </form>
    </AppPage>
  )
}

export default DocumentList