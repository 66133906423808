import React from "react"
import { ActionIcon, Card, Highlight, Loader, Text } from "@mantine/core"
import styles from "./index.module.scss"
import { filesize } from "filesize"
import join from "clsx"
import { Download } from "tabler-icons-react"


function FileItem({
  extra,
  file,
  actions,
  className,
  selected,
  loading,
  error,
  color,
  style,
  highlight,
  onClick,
  ...props
}) {
  const { id, name = "", size: _size, url } = file || {}
  const size = id ? _size * 1024 : _size
  if (!file) return null
  return (
    <Card
      onClick={onClick}
      className={join(
        styles.fileCard,
        error && styles.error,
        selected && styles.selected,
        onClick && styles.clickable,
        className
      )}
      style={{ background: color, ...style }}
      {...props}
    >
      {extra && <div className={styles.extra}>{extra}</div>}
      <div className={styles.left}>
        <Highlight
          weight="bold"
          size="sm"
          className="elipsis"
          highlight={highlight}
        >
          {name}
        </Highlight>
        {size &&
          <Text color="gray" size=".75rem">
            {filesize(size)}
          </Text>
        }
      </div>
      <div className={styles.actions} onClick={(event) => event.stopPropagation()}>
        {loading && <Loader size="sm" color="dark" />}
        {!loading &&
          <>
            {url &&
              <ActionIcon
                size="sm"
                component="a"
                download
                href={url}
                target="_blank"
              >
                <Download />
              </ActionIcon>
            }
            {actions}
          </>
        }
      </div>
    </Card>
  )
}

export default React.memo(FileItem)
