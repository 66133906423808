import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"
import { requestActions, createRequest } from "modules/requests"
import ProviderSection from "./ProviderSection"
import ServicesSection from "./ServicesSection"
import FormSection from "./FormSection"
import { useFormik } from "formik"
import { useProvider } from "modules/providers"
import join from "clsx"
import { hideModal } from "core/modals"
import { Button } from "@mantine/core"
import { Send } from "tabler-icons-react"
import AppModal from "components/AppModal"
import { actions } from "core/store"
import * as yup from "yup"
import PriceSection from "./PriceSection"
import pageActions, { PageContext } from "core/pages"


function RequestModal({ providerId }) {
  const { t } = useTranslation()
  const { compact } = React.useContext(PageContext)
  const formik = useFormik({
    initialValues: {
      text: "",
      files: [],
      ...requestActions.get()
    },
    validationSchema: yup.object({
      text: yup.string().required(t("field_is_required"))
    }),
    validate: (values) => requestActions.update(values),
    onSubmit: async (values) => {
      const request = await createRequest(values)
      const { lastMessage = {} } = request || {}
      actions.set(`messages.${request.id}.${lastMessage.id}`, lastMessage)
      pageActions
        .load("RequestChat")
        .set({ requestId: request.id })
        .replace()
      requestActions.unset()
    }
  })

  const { values } = formik
  const [provider] = useProvider(values.providerId)
  const props = { formik, provider }

  React.useEffect(() => {
    formik.setFieldValue("providerId", providerId)
  }, [providerId])


  return (
    <AppModal
      size="xl"
      className={join(styles.requestModal, compact && styles.compact)}
      name={t("new_chat")}
      footer={
        <>
          <Button
            color="dark"
            leftIcon={<Send />}
            onClick={formik.handleSubmit}
            radius="lg"
          >
            {t("send_message")}
          </Button>
        </>
      }
    >

      <ProviderSection {...props} />
      <FormSection {...props} />
      <ServicesSection {...props} />
      <PriceSection {...props} />
    </AppModal>
  )
}

export default RequestModal

