import { Text, Title } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"

function ServicesSection() {
  const { t } = useTranslation()
  return (
    <>
      <div />
      <Title order={4} >{t("request_services")}</Title>
      <Text size="sm">{t("services_section_description_1")}</Text>
      <Text size="sm">{t("services_section_description_2")}</Text>
      <Text size="sm">{t("services_section_description_3")}</Text>
    </>
  )
}

export default React.memo(ServicesSection)
