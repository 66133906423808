import { Box, Card, Loader, Text } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import FileItem from "modules/documents/FileItem"
import join from "clsx"
import dayjs from "dayjs"
import { AlertCircle, Check, Checks } from "tabler-icons-react"
import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"
import DynamicItem from "modules/dynamics/DynamicItem"
import _ from "lodash"
import EmptyDocument from "modules/documents/EmptyDocument"
import { useIsProvider } from "modules/auth"
import { viewMessage } from "../messages"


function RequestMessage({
  message,
  current,
  first,
  className,
  style,
  loading,
  compact
}) {
  const isProvider = useIsProvider()
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    id,
    text,
    documents = [],
    transaction,
    createdAt,
    error,
    meta,
    client_viewed_at,
    provider_viewed_at
  } = message || {}

  const shouldView = Boolean(
    (isProvider && !provider_viewed_at) ||
    (!isProvider && !client_viewed_at)
  )

  const isViewed = Boolean(
    (isProvider && client_viewed_at) ||
    (!isProvider && provider_viewed_at)
  )


  React.useEffect(() => {
    if (!loading && shouldView) viewMessage(id)
  }, [loading, shouldView])

  const { dynamic_services = [], payment_url, status, protected_message = {} } = transaction || {}
  const { type, documentIds } = meta || {}
  const isDynamicService = !_.isEmpty(dynamic_services)
  const isSystem = type === "system"


  const [ref, setRef] = React.useState()
  React.useEffect(() => {
    if (ref && loading) {
      ref.scrollIntoView()
    }
  }, [ref])

  const color = React.useMemo(() => {
    if (isDynamicService) return theme.colors.gray[6]
    if (current && !isSystem) return theme.white
    return theme.colors.gray[6]
  })

  return (
    <div
      ref={(node) => setRef(node)}
      className={join(
        styles.requestMessage,
        current && !isSystem && !isDynamicService && styles.current,
        first && styles.first,
        error && styles.error,
        compact && styles.compact,
        className
      )}
    >
      <Card
        radius="md"
        style={style}
        className={styles.message}
      >
        <div className={styles.content}>
          {dynamic_services.map((dynamic) => {
            const { id } = dynamic
            return (
              <DynamicItem
                key={id}
                dynamic={dynamic}
                protected_message={protected_message}
                status={status}
                payment_url={payment_url}
                current={current}
              />
            )
          })}
          {documents.map((document) => {
            const { id, file, loading } = document
            return (
              <FileItem
                key={id}
                file={file}
                color={current && "white"}
                loading={loading}
              />
            )
          })}
          {!_.isEmpty(documentIds) && _.isEmpty(documents) &&
            <EmptyDocument />
          }
          {text &&
            <Text size="sm" className={join(styles.text, "multiline")} component="span">
              {isSystem
                ? <><Box c="blue" fz="md" fw="bold" component="span">⚠️</Box>{" "}{t(text)}</>
                : text
              }
            </Text>
          }
        </div>
        <div className={styles.meta}>
          <Text size="xs" color={color} style={{ lineHeight: .75 }}>
            {dayjs(createdAt).format("HH:mm")}
          </Text>
          {current &&
            <>
              {loading && <Loader size={14} color={color} className={styles.icon} />}
              {!loading &&
                <>
                  {error && <Text size="xs" style={{ lineHeight: .75 }}>{t(error)}</Text>}
                  {!error &&
                    <>
                      {!isViewed && <Check color={color} size={14} className={styles.icon} />}
                      {isViewed && <Checks color={color} size={14} className={styles.icon} />}
                    </>
                  }
                </>
              }
            </>
          }
          {error && <AlertCircle color={theme.white} size={14} className={styles.icon} />}
        </div>

      </Card>

    </div>

  )
}

export default React.memo(RequestMessage)
