import { ActionIcon, Avatar, Card, Tooltip } from "@mantine/core"
import { getUserInfo, selectCurrentUser, useIsAuthenticated, useIsProvider } from "modules/auth"
import { t } from "i18next"
import React from "react"
import { Folders, Scale, Messages, Plus, Users, InputSearch, User } from "tabler-icons-react"
import styles from "./index.module.scss"
import AppLink from "components/AppLink"
import Logo from "components/Logo"
import { showModal } from "core/modals"
import LoginModal from "modules/auth/LoginModal"
import { showRequestCreate } from "modules/requests"
import pageActions from "core/pages"
import UserModal from "modules/auth/UserModal"
import { useSelector } from "lodash-redux"


function AppSidebar() {
  const isProvider = useIsProvider()
  const authenticated = useIsAuthenticated()
  const user = useSelector(() => selectCurrentUser())
  const { initials, photoUrl } = getUserInfo(user)

  return (
    <Card
      shadow="lg"
      withBorder
      className={styles.appSidebar}
    >
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <div className={styles.center}>
          {!isProvider &&
            <>
              <AppLink
                radius="50%"
                variant="filled"
                color="primary"
                icon={<Plus />}
                onClick={() => showRequestCreate()}
                label={t("contact_provider")}
              />
              <AppLink
                icon={<Scale />}
                to={pageActions.load("ProviderList").path}
                label={t("providers")}
              />
            </>
          }
          {authenticated &&
            <>
              {isProvider &&
                <>
                  <AppLink
                    icon={<Users />}
                    to={pageActions.load("RequestList").path}
                    label={t("clients")}
                  />
                  <AppLink
                    icon={<Folders />}
                    to={pageActions.load("ProjectList").path}
                    label={t("projects")}
                  />
                  <Tooltip label={t("portal_just")} position="right">
                    <ActionIcon
                      component="a"
                      href="https://portal.just.ro/SitePages/acasa.aspx"
                      target="_blank"
                    >
                      <InputSearch />
                    </ActionIcon>
                  </Tooltip>
                </>
              }
              {!isProvider &&
                <>
                  <AppLink
                    icon={<Messages />}
                    to={pageActions.load("RequestList").path}
                    label={t("chat")}
                  />
                </>
              }
            </>
          }
        </div>
        <div className={styles.bottom}>
          {!authenticated &&
            <Avatar
              radius="50%"
              variant="light"
              className={styles.avatar}
              onClick={() => showModal(LoginModal)}
              style={{ cursor: "pointer" }}
            >
              <User />
            </Avatar>
          }
          {authenticated &&
            <Avatar
              radius="50%"
              variant="filled"
              src={photoUrl}
              className={styles.avatar}
              onClick={() => showModal(UserModal)}
              style={{ cursor: "pointer" }}
            >
              {initials || <User />}
            </Avatar>
          }
        </div>
      </div>
    </Card>
  )
}

export default AppSidebar
