import { createBrowserHistory } from "history"
import { useParams as _useParams, useLocation } from "react-router-dom"
import qs from "qs"
import _ from "lodash"
import React from "react"


const history = createBrowserHistory()


export const useParams = () => {
  let params = _useParams()
  params = React.useMemo(() => _.mapValues(params, (value, key) => {
    if (key.toLowerCase().includes("id") && !isNaN(value)) return Number(value)
    return value
  }), [params])
  return params
}

export const useSearch = () => {
  const { search } = useLocation()
  const params = React.useMemo(() => {
    const searchObject = qs.parse(search, { ignoreQueryPrefix: true })
    return _.mapValues(searchObject, (value, key) => {
      if (key.toLowerCase().endsWith("id") && !_.isEmpty(value) && !isNaN(value)) {
        return Number(value)
      }
      return value
    })
  }, [search])
  return params
}


export default history