import React from "react"
import ProviderList from "modules/providers/ProviderList"
import RequestChat from "modules/requests/RequestChat"
import RequestList from "modules/requests/RequestList"
import RequestSend from "modules/requests/RequestSend"
import SettingsView from "modules/auth/SettingsView"
import ProjectList from "modules/projects/ProjectList"
import ProviderView from "modules/providers/ProviderView"
import TimesheetList from "modules/timesheets/TimesheetList"
import DocumentList from "modules/documents/DocumentList"
import { PageContext, useBreakpoint, usePage } from "core/pages"
import styles from "./index.module.scss"
import join from "clsx"
import { useIsProvider } from "modules/auth"

const COMPONENTS = {
  SettingsView,
  ProviderList,
  ProviderView,
  RequestList,
  RequestChat,
  ProjectList,
  RequestSend,
  TimesheetList,
  DocumentList
}

function PageDispatcher() {
  const md = useBreakpoint("md")
  const isProvider = useIsProvider()
  const [name, values] = usePage()

  const Page = React.useMemo(() => {
    const Component = COMPONENTS[name]
    if (Component) return Component
    if (isProvider) return RequestList
    return ProviderList
  }, [name, isProvider])

  return (
    <div className={join(styles.pageDispatcher, md && styles.md)}>
      <PageContext.Provider
        value={{
          compact: md
        }}
      >
        {Page && <Page {...values} />}
      </PageContext.Provider>
    </div>
  )
}

export default PageDispatcher
