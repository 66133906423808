import { Button } from "@mantine/core"
import { createMessage } from "modules/requests/messages"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"
import { createDynamic, sendDynamic } from "modules/dynamics"
import { CreditCard, HandStop } from "tabler-icons-react"
import { useSelector } from "lodash-redux"
import { selectCurrentUser } from "modules/auth"
import { actions } from "core/store"
import { clearLoader, setLoader, useLoader } from "core/loaders"


function ProviderActions({ request }) {
  const { i18n, t } = useTranslation()
  const formater = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "RON",
    maximumSignificantDigits: 3
  })

  const { initial_service_price } = useSelector(() => selectCurrentUser())
  const { id } = request || {}
  const requesting = useLoader(`requesting.${id}`)

  return (
    <div className={styles.providerActions}>
      <Button
        radius="lg"
        color="red"
        size="xs"
        variant="outline"
        leftIcon={<HandStop size="1.25rem" />}
        onClick={async () => {
          try {
            actions.set(`requests.${id}.provider_responded`, true)
            await createMessage({
              text: "your_request_has_been_denied",
              requestId: id,
              type: "system"
            })
          } catch (error) {
            actions.unset(`requests.${id}.provider_responded`)
          }
        }}
      >
        {t("deny_request")}
      </Button>
      <Button
        color="dark"
        radius="lg"
        size="xs"
        loading={requesting}
        leftIcon={<CreditCard size="1.25rem" />}
        onClick={async () => {
          try {
            setLoader(`requesting.${id}`)
            const dynamic = await createDynamic({
              price: initial_service_price,
              description: "request_is_pending_payment"
            })
            await sendDynamic(dynamic.id, { requestId: id })
            actions.set(`requests.${id}.provider_responded`, true)
          } finally {
            clearLoader(`requesting.${id}`)
          }
        }}
      >
        {`${t("request")} ${formater.format(initial_service_price)}`}
      </Button>
    </div>
  )

}


export default React.memo(ProviderActions)
