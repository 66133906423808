import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import { Loader, MantineProvider } from "@mantine/core"
import store, { initStore } from "core/store"
import { Provider } from "lodash-redux"
import { initAuth } from "modules/auth"
import { Router } from "react-router-dom"
import history from "core/history"
import { Notifications } from "@mantine/notifications"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import localizedFormat from "dayjs/plugin/localizedFormat"
import isBetween from "dayjs/plugin/isBetween"
import duration from "dayjs/plugin/duration"
import { ModalsProvider } from "@mantine/modals"


dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(document.getElementById("root"))


const start = async () => {
  root.render(
    <div className="centered-content">
      <Loader />
    </div>
  )
  // TODO: isMobile query state initialization
  await initStore()
  await initAuth()


  root.render(
    <Provider store={store}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        withCSSVariables
        theme={{ primaryColor: "blue" }}
      >
        <ModalsProvider>
          <Notifications position="bottom-right" />
          <Router history={history}>
            <App />
          </Router>
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  )
}


start()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
