
import cleanDeep from "clean-deep"
import axios from "core/axios"
import { clearLoader, setLoader } from "core/loaders"
import { actions } from "core/store"
import { useData, useItem } from "core/data"
import { createDocument } from "modules/documents"
import _ from "lodash"
import { showModal } from "core/modals"
import DynamicModal from "./DynamicModal"


export const dynamicActions = actions.create("layout.dynamicActions")

const DYNAMIC_POPULATE = ["request", "message"]

export const useDynamics = (params, deps) => {
  return useData("dynamics", fetchDynamics, params, deps)
}

const fetchDynamics = async (params) => {
  const { filters, pagination, ...query } = params || {}
  const { start = 0, limit = 100 } = pagination || {}
  const { data = [] } = await axios.get("/dynamic-services", {
    params: {
      pagination: { start, limit },
      filters,
      populate: DYNAMIC_POPULATE,
      ...query
    }
  })

  const items = data.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})

  actions.update("dynamics", items)
  return items
}

export const createDynamic = async ({ description, price, type }) => {
  try {
    setLoader("createDynamic")
    const { data: item } = await axios.post("/dynamic-services", {
      data: {
        price,
        name: "dynamic_service",
        description
      }
    }, { params: { populate: DYNAMIC_POPULATE } })
    actions.set(`dynamics.${item.id}`, item)
    return item
  } finally {
    clearLoader("createDynamic")
  }
}

export const sendDynamic = async (id, { requestId, description, files }) => {
  try {
    setLoader("sendDynamic")
    const protected_documents = []
    if (!_.isEmpty(files)) {
      for (const file of files) {
        const document = await createDocument({ file })
        protected_documents.push(document.id)
      }
    }
    const item = await axios.post(`/dynamic-services/${id}/send/${requestId}`, { data: {
      payment_return_url: window.location.href,
      protected_text: description,
      protected_documents
    } })
    actions.set(`dynamics.${item.id}`, item)
    return item
  } finally {
    clearLoader("sendDynamic")
  }
}


export const updateDynamic = async (id, {
  name,
  price,
  description,
  requestId,
  messageId
}) => {
  try {
    setLoader("updateDynamic")
    const formData = new FormData()
    formData.append(
      "data",
      JSON.stringify(cleanDeep({
        name,
        price,
        request: requestId,
        message: messageId,
        meta: { description }
      }))
    )
    const { data: item } = await axios.post(`/dynamic-services/${id}`, null, {
      params: { populate: DYNAMIC_POPULATE }
    })
    actions.set(`dynamics.${item.request.id}.${item.id}`, item)
    return item
  } finally {
    clearLoader("updateDynamic")
  }
}


export const showDynamicModal = (requestId) => {
  showModal(DynamicModal, {
    onSubmit: async ({ name, description, price, files }) => {
      const dynamic = await createDynamic({ name, price })
      await sendDynamic(dynamic.id, { requestId, description, files })
    }
  })
}


export const useProtectedMessage = (id, params) => useItem(id, "protectedMessage", fetchProtectedMessage, params)
const fetchProtectedMessage = async (id) => {
  try {
    setLoader("fetchProtectedMessage")
    const { data: item } = await axios.get(`/protected-messages/${id}`, {
      params: { populate: [
        "protected_documents.file",
        "protected_text"
      ] }
    })
    actions.set(`protectedMessage.${id}`, item)
    return item
  } finally {
    clearLoader("fetchProtectedMessage")
  }
}