import { ActionIcon, Avatar, Button, CloseButton, Indicator, Text, TextInput, Title } from "@mantine/core"
import { getRequestInfo, useRequests } from "modules/requests"
import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import styles from "./index.module.scss"
import { useFormik } from "formik"
import { Messages, Plus, Scale, Search, Users } from "tabler-icons-react"
import PageHeader from "components/PageHeader"
import AppPage from "components/AppPage"
import { useIsProvider } from "modules/auth"
import { showModal } from "core/modals"
import InviteModal from "modules/clients/InviteModal"
import dayjs from "dayjs"
import TagFilters from "components/TagFilters"
import { formatCreatedAt, includesLowerCase, STATUS_COLORS } from "core"
import { Link } from "react-router-dom"
import pageActions, { updateValues } from "core/pages"
import AppTable from "components/AppTable"
import FavoriteIcon from "modules/clients/FavoriteIcon"


const REQUEST_FILTERS = {
  enabledCRM: "enabledCRM",
  has_user_activity: "has_user_activity"
}


function RequestList({ request_search = "", request_tag }) {
  const { t } = useTranslation()
  const isProvider = useIsProvider()
  const [requests, loading] = useRequests()

  const filteredList = React.useMemo(() => Object.values(requests).filter((request) => {
    const conditions = []
    if (request_search) {
      const acc = []
      const info = getRequestInfo({ isProvider, request })
      Object.values(info).forEach((value) => {
        acc.push(includesLowerCase(value, request_search))
      })
      conditions.push(acc.some(Boolean))
    }

    if (isProvider) {
      if (request_tag === REQUEST_FILTERS.has_user_activity) conditions.push(request.has_user_activity)
      if (request_tag === REQUEST_FILTERS.enabledCRM) {
        const { provider_client } = request || {}
        const { enabledCRM } = provider_client || {}
        conditions.push(enabledCRM)
      }
    }

    return conditions.every(Boolean)
  }), [requests, isProvider, request_search, request_tag])


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      request_search,
      request_tag
    },
    validate: (values) => updateValues(values)
  })
  const { values } = formik

  const name = isProvider ? t("clients") : t("chat")
  const placeholder = isProvider ? t("search_for_clients") : t("search_chats")
  const icon = isProvider ? <Users /> : <Messages />

  return (
    <AppPage
      loading={loading && _.isEmpty(filteredList)}
      className={styles.requestList}
      header={
        <PageHeader
          icon={icon}
          name={name}
          toolbar={
            <>
              <div className={styles.toolbar}>
                <div className={styles.row}>
                  <TextInput
                    name="request_search"
                    className={styles.search}
                    variant="filled"
                    icon={<Search />}
                    placeholder={placeholder}
                    value={values.request_search}
                    onChange={formik.handleChange}
                    rightSection={values.request_search &&
                      <CloseButton onClick={() => formik.setFieldValue("request_search", "")} />
                    }
                  />
                  {isProvider &&
                    <ActionIcon
                      size="lg"
                      variant="filled"
                      color="dark"
                      onClick={() => showModal(InviteModal, {
                        onSubmit: (request) => {
                          pageActions
                            .load("RequestChat")
                            .set({ requestId: request.id })
                            .replace()
                        }
                      })}
                    >
                      <Plus />
                    </ActionIcon>
                  }
                </div>

              </div>
            </>
          }
        />
      }
    >
      {isProvider &&
        <TagFilters
          value={values.request_tag}
          onChange={(request_tag) => formik.setValues({ request_tag, request_search: "" })}
          options={Object.values(REQUEST_FILTERS).map(((value) => ({
            value,
            label: t(value),
            color: STATUS_COLORS[value]
          })))}
        />
      }
      <AppTable
        loading={loading && _.isEmpty(filteredList)}
        rows={_.orderBy(filteredList,
          ["blocked", ({ lastMessage = {} }) => dayjs(lastMessage.createdAt).valueOf()],
          ["asc", "desc"]
        )}
        placeholder={
          <div className={styles.placeholder}>
            <Text color="gray" size="sm">
              {_.isEmpty(requests)
                ? t("no_chats_available")
                : t("no_chats_found")
              }
            </Text>
            {_.isEmpty(requests) && !isProvider &&
              <Button
                radius="lg"
                color="dark"
                variant="outline"
                size="sm"
                leftIcon={<Scale />}
                className={styles.button}
                component={Link}
                to={pageActions.load("ProviderList").path
                }
              >
                {t("add_provider")}
              </Button>
            }
          </div>
        }
        onRowClick={({ id }) => {
          pageActions
            .load("RequestChat")
            .update({ requestId: id })
            .push()
        }}
        columns={[
          {
            id: "avatar",
            flex: "none",
            alignItems: "flex-start",
            render: (request) => {
              const { photoUrl, initials } = getRequestInfo({ isProvider, request })
              const {
                blocked,
                has_member_activity,
                has_user_activity
              } = request || {}
              const status = (() => {
                switch (true) {
                  case (!!blocked): return "blocked"
                  case (
                    (!isProvider && has_member_activity) ||
                    (isProvider && has_user_activity)
                  ): return "has_activity"
                  default: return null
                }
              })()
              return (
                <Indicator
                  disabled={!status}
                  color={STATUS_COLORS[status]}
                  offset={7}
                  position="bottom-end"
                  size={12}
                >
                  <Avatar
                    radius="50%"
                    variant="light"
                    size="2.5rem"
                    src={photoUrl}
                    className={styles.avatar}
                  >
                    {initials}
                  </Avatar>
                </Indicator>
              )
            }
          },
          {
            id: "name",
            render: (request) => {
              const { name, description } = getRequestInfo({ isProvider, request })
              const {
                id,
                lastMessage = {},
                createdAt
              } = request || {}
              const { text, documents, transaction, meta } = lastMessage || {}

              const messageText = (() => {
                const { type } = meta || {}
                if (type === "system") return t(text)
                if (text) return text
                if (!_.isEmpty(documents)) return t("file_was_uploaded")
                if (!_.isEmpty(transaction)) return t("payment_was_requested")
                return ""
              })()

              return (
                <div className={styles.column}>
                  <div className={styles.row}>
                    <Title order={6} className="elipsis">
                      {name}
                    </Title>
                    {isProvider && <FavoriteIcon requestId={id} />}
                    <div className={styles.extra} >
                      <Text size="xs" color="gray" className="elipsis">
                        {formatCreatedAt(lastMessage.createdAt || createdAt)}
                      </Text>
                    </div>
                  </div>
                  <Text size="xs" className="elipsis" color="gray">
                    {description}
                  </Text>
                  {messageText &&
                    <Text size="xs" lineClamp={2}>
                      {messageText}
                    </Text>
                  }
                </div>
              )
            }
          }
        ].filter(Boolean)}
      />
    </AppPage>
  )
}


export default RequestList
