import { useSelector } from "react-redux"
import { actions } from "core/store"
import React from "react"
import { hideModal, showModal } from "core/modals"
import LoaderModal from "./LoaderModal"
import { useTranslation } from "react-i18next"
import { EMPTY_OBJECT } from "core"


export const useLoader = (path) => {
  const loading = useSelector(() => selectLoader(path))
  const [_loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    setTimeout(() => setLoading(loading))
  }, [loading])
  return _loading
}

export const selectLoaders = (path) => actions.get(`loaders.${path}`, EMPTY_OBJECT)
export const selectLoader = (path) => actions.get(`loaders.${path}`, 0) > 0
export const setLoader = (path) => actions.update(`loaders.${path}`, (loader = 0) => loader + 1)
export const clearLoader = (path) => setTimeout(() => {
  actions.update(`loaders.${path}`, (loader = 0) => loader - 1)
})


export const useLoaderModal = (loaderPaths = []) => {
  const loading = useSelector(() => {
    let loading
    for (const path of loaderPaths) {
      loading = loading || (selectLoader(path) && path)
    }
    return loading
  })


  React.useEffect(() => {
    if (loading) {
      showModal(LoaderModal, { message: loading })
      return () => hideModal()
    }
  }, [loading])
}