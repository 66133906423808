import { Button, Text } from "@mantine/core"
import { useProvider } from "modules/providers"
import React from "react"
import _ from "lodash"
import styles from "./index.module.scss"
import ProfileCard from "./ProfileCard"
import ActivityCard from "./ActivityCard"
import JobsCard from "./JobsCard"
import EducationCard from "./EducationCard"
import AppCard from "components/AppCard"
import { useTranslation } from "react-i18next"
import AppPage from "components/AppPage"
import PageHeader from "components/PageHeader"
import join from "clsx"
import { useIsProvider } from "modules/auth"
import { Calendar, Scale, Send } from "tabler-icons-react"
import { selectRequests, showRequestCreate } from "modules/requests"
import { useSelector } from "lodash-redux"
import { Link } from "react-router-dom"
import { useLoader } from "core/loaders"
import pageActions, { PageContext } from "core/pages"


const TBD = false

function ProviderView({ providerId, requestId }) {
  const { t } = useTranslation()
  const { compact } = React.useContext(PageContext)
  const [provider, loading] = useProvider(providerId)
  const isProvider = useIsProvider()
  const { id, appointment_link, profile } = provider || {}
  const { description } = profile || {}

  const requests = useSelector(() => selectRequests())
  const request = _.find(requests, ({ members }) => {
    const [provider] = members || []
    const { id } = provider || {}
    return id === providerId
  })

  const showing = useLoader(`showRequestCreate.${providerId}`)

  return (
    <AppPage
      size="xl"
      header={
        <PageHeader
          icon={<Scale />}
          name={t("provider_profile")}
          showBack
          onBack={() => {
            if (requestId) {
              pageActions.load("RequestChat").update().replace()
            } else {
              pageActions.load("ProviderList").update().replace()
            }

          }}
        />
      }
      className={join(styles.providerView, compact && styles.compact)}
      loading={_.isEmpty(provider) && loading}
    >
      {!_.isEmpty(provider) &&
        <div className={styles.content}>
          <ProfileCard
            provider={provider}
            actions={!isProvider &&
              <>
                {appointment_link &&
                  <Button
                    color="dark"
                    variant="outline"
                    radius="lg"
                    leftIcon={<Calendar />}
                    component="a"
                    target="_blank"
                    href={appointment_link}
                  >
                    {t("appointment")}
                  </Button>
                }
                {!request &&
                  <Button
                    radius="lg"
                    color="dark"
                    leftIcon={<Send />}
                    loading={showing}
                    onClick={() => showRequestCreate({ providerId: id })}
                  >
                    {t("send_message")}
                  </Button>
                }
                {request &&
                  <Button
                    radius="lg"
                    color="dark"
                    rightIcon={<Send />}
                    component={Link}
                    to={pageActions
                      .load("RequestChat")
                      .set({ requestId: request.id })
                      .path
                    }
                  >
                    {t("send_message")}
                  </Button>
                }
              </>
            }
          />
          <AppCard name={t("about")}>
            <Text size="sm" className="multiline">
              {description}
            </Text>
          </AppCard>
          {TBD && <ActivityCard provider={provider} />}
          <JobsCard provider={provider} />
          <EducationCard provider={provider} />
        </div>
      }
    </AppPage>
  )
}

export default ProviderView
