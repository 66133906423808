import { Badge, Avatar, List, Text, Title, Input, Tooltip, Indicator, Box } from "@mantine/core"
import AppCard from "components/AppCard"
import { getUserInfo } from "modules/auth"
import React from "react"
import { useTranslation } from "react-i18next"
import { Briefcase, CreditCard, Hourglass, School } from "tabler-icons-react"
import _ from "lodash"
import styles from "./index.module.scss"
import { getYearRange } from "modules/providers"
import { useBreakpoint } from "core/layout"
import { STATUS_COLORS } from "core"
import IconText from "components/IconText"
import pageActions from "core/pages"


function ProfileCard({ provider, actions }) {
  const { i18n, t } = useTranslation()
  const formater = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "RON",
    maximumSignificantDigits: 3
  })

  const sm = useBreakpoint("sm")
  const { status, specializations = [], profile, initial_service_price = 89 } = provider || {}
  const { title, experience = 0, address, jobs, education } = profile || {}
  const [job = {}] = _.castArray(jobs)
  const [school = {}] = _.castArray(education)
  const { city, country } = address || {}
  const { name, photoUrl } = getUserInfo(provider)


  return (
    <AppCard>
      <div className={styles.top}>
        <Avatar
          size={sm ? "12rem" : "10rem"}
          src={photoUrl}
          className={styles.avatar}
          alt="photoUrl"
          radius="50%"
          variant="filled"
        >

        </Avatar>
        <div className={styles.left}>
          <div className={styles.row}>
            <Title order={2}>{name}</Title>
            <Tooltip
              label={t(`provider_${status}`)}
              position="right"
            >
              <Indicator
                position="middle-end"
                color={STATUS_COLORS[status]}
                zIndex={0}
              />
            </Tooltip>
          </div>
          <Input.Wrapper
            label={<Text className={styles.subTitle}>{title}</Text>}
            description={
              <div className={styles.info}>
                <IconText
                  className={styles.address}
                  value={[city, country].filter(Boolean).join(", ")}
                />
                <IconText
                  icon={<CreditCard />}
                  value={
                    <>
                      {`${t("starting_price")}:`}
                      {" "}
                      <Text span weight="bold" color="gray">
                        {`${formater.format(initial_service_price)}`}
                      </Text>
                    </>
                  }
                />
              </div>

            }
          />
          <div className={styles.tags}>
            {specializations.map(({ id, name }) => (
              <Badge
                radius="lg"
                key={name}
                className={styles.tag}
                color="gray"
                onClick={() => pageActions.load("ProviderList").update({ specializationId: id }).push()}
              >
                {t(name)}
              </Badge>
            ))}
          </div>
          {actions &&
            <div className={styles.actions}>
              {actions}
            </div>
          }

        </div>
      </div>
      <div className={styles.bottom}>
        <List
          spacing="xs"
          size="sm"
        >
          <SummaryItem
            icon={<School />}
            label={school.name}
            value={getYearRange(school)}
          />
          <SummaryItem
            icon={<Briefcase />}
            label={job.name}
            value={job.address}
          />
          <SummaryItem
            icon={<Hourglass />}
            label={t("experience")}
            value={`${experience} ${t("years")}`}
          />
        </List>
      </div>
    </AppCard>
  )
}

function SummaryItem({ icon, label, value }) {
  return (
    <List.Item
      icon={icon}
      className={styles.summaryItem}
    >
      <div className={styles.itemContent}>
        <Title order={6} >
          {label}
        </Title>
        <Text size="sm">
          {value}
        </Text>
      </div>
    </List.Item>
  )
}

export default React.memo(ProfileCard)
