import { Checkbox, SegmentedControl, Text } from "@mantine/core"
import { selectCurrentUser, updateUser } from "modules/auth"
import { changeLanguage, LANGUAGES } from "core/i18n"
import React from "react"
import { useTranslation } from "react-i18next"
import { Settings } from "tabler-icons-react"
import { useSelector } from "lodash-redux"
import PasswordSection from "./PasswordSection"
import styles from "./index.module.scss"
import AppPage from "components/AppPage"
import AppCard from "components/AppCard"
import PageHeader from "components/PageHeader"
import join from "clsx"
import { EMPTY_OBJECT } from "core"
import _ from "lodash"
import InfoSection from "./InfoSection"
import { PageContext } from "core/pages"


function SettingsView() {
  const { t, i18n } = useTranslation()
  const { compact } = React.useContext(PageContext)
  const user = useSelector(() => selectCurrentUser())
  const { notificationsEmail } = user || {}
  const [footers, setFooters] = React.useState(EMPTY_OBJECT)


  return (
    <AppPage
      header={
        <PageHeader
          icon={<Settings />}
          name={t("settings")}
        />
      }
      className={join(styles.settingsView, compact && styles.compact)}
      footer={_.last(Object.values(footers))}
    >
      <div className={styles.content} >
        <InfoSection name="info" setFooters={setFooters} user={user} />
        <AppCard name={t("notifications")}>
          <Checkbox
            className={styles.switch}
            label={t("email_notifications")}
            checked={!!notificationsEmail}
            onChange={() => {
              updateUser({ notificationsEmail: !notificationsEmail })
            }}
          />
        </AppCard>
        <PasswordSection name="password" setFooters={setFooters} />
        <AppCard name={t("language")}>
          <SegmentedControl
            style={{ maxWidth: 250 }}
            value={i18n.language}
            onChange={(value) => changeLanguage(value)}
            data={LANGUAGES.map((language) => ({
              label: t(`full_${language}`),
              value: language
            }))}
          />
        </AppCard>
      </div>
    </AppPage>
  )
}

export default SettingsView
