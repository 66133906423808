import { Button, PasswordInput } from "@mantine/core"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"
import _ from "lodash"
import { setAlertSuccess } from "core/alerts"
import cleanDeep from "clean-deep"
import { passwordReset } from "modules/auth"
import AppModal from "components/AppModal"


function ResetModal({ code }) {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: ""
    },
    validate: ({ password, passwordConfirmation }) => {
      let errors = {}
      if (password && password !== passwordConfirmation) {
        errors.password = t("passwords_do_not_match")
      }
      if (!password) errors.password = t("password_is_required")
      return errors
    },
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        const { password, passwordConfirmation } = values
        await passwordReset({ code, password, passwordConfirmation })
        setAlertSuccess(t("password_changed_succesfully"))
      } catch (error) {
        formik.setFieldError("password", t(error.message))
      } finally {
        formik.setSubmitting(false)
      }
    }
  })
  const { errors, values, touched } = formik
  const { password, passwordConfirmation } = values
  const isTouched = !_.isEmpty(cleanDeep(touched))

  return (
    <AppModal
      size="sm"
      className={styles.resetModal}
      name={t("reset_password")}
    >
      <PasswordInput
        radius={0}
        variant="filled"
        name="password"
        value={password}
        onChange={formik.handleChange}
        label={t("new_password")}
        error={isTouched && !_.isEmpty(errors)}
      />
      <PasswordInput
        radius={0}
        variant="filled"
        name="passwordConfirmation"
        value={passwordConfirmation}
        onChange={formik.handleChange}
        label={t("confirm_password")}
        error={isTouched && errors.password}
      />
      <div className={styles.divider} />
      <Button
        radius="lg"
        color="dark"
        loading={formik.isSubmitting}
        className={styles.button}
        onClick={formik.handleSubmit}
      >
        {t("reset_password")}
      </Button>
    </AppModal>
  )
}

export default ResetModal

