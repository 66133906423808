import {
  ActionIcon,
  Button,
  CloseButton,
  Text,
  TextInput,
  Title
} from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import styles from "./index.module.scss"
import { useFormik } from "formik"
import { PROJECT_STATUSES } from "modules/projects"
import { Search, Folders, Plus, Pencil, User, Files } from "tabler-icons-react"
import join from "clsx"
import PageHeader from "components/PageHeader"
import { useProjects } from "modules/projects"
import ProjectModal from "../ProjectModal"
import AppPage from "components/AppPage"
import TagFilters from "components/TagFilters"
import { includesLowerCase, STATUS_COLORS } from "core"
import { useRequest } from "modules/requests"
import AppTable from "components/AppTable"
import StatusBadge from "components/StatusBadge"
import { getUserInfo } from "modules/auth"
import { showModal } from "core/modals"
import pageActions, { PageContext, updateValues } from "core/pages"
import ClientModal from "modules/clients/ClientModal"


function ProjectList({ requestId, project_search = "", project_tag }) {
  const { compact } = React.useContext(PageContext)
  const { t } = useTranslation()

  const [request] = useRequest(requestId)
  const { provider_client = {} } = request
  const { id: clientId, name: clientName } = provider_client

  const [projects, loading] = useProjects({
    disabled: requestId && !clientId,
    filters: { provider_client: clientId }
  }, [clientId])

  const filteredList = React.useMemo(() => Object.values(projects).filter((project) => {
    const conditions = []
    if (requestId) conditions.push(project.requestId === requestId)
    if (project_search) conditions.push(includesLowerCase(project.name, project_search))
    if (project_tag) conditions.push(project.status === project_tag)
    return conditions.every(Boolean)
  }), [projects, project_search, project_tag, requestId])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_search,
      project_tag
    },
    validate: (values) => updateValues(values)
  })
  const { values } = formik


  return (
    <AppPage
      className={join(styles.projectsList, compact && styles.compact)}
      header={
        <PageHeader
          icon={<Folders />}
          name={t("projects")}
          description={requestId && clientName}
          hiddenBack={!requestId}
          onBack={() => {
            pageActions
              .load("RequestChat")
              .update({ requestId })
              .replace()
          }}
          toolbar={
            <div className={styles.toolbar}>
              <div className={styles.row}>
                <TextInput
                  name="project_search"
                  className={styles.search}
                  variant="filled"
                  icon={<Search />}
                  placeholder={t("search_for_projects")}
                  value={values.project_search}
                  onChange={formik.handleChange}
                  rightSection={values.project_search && (
                    <CloseButton
                      onClick={() => formik.setFieldValue("project_search", "")}
                    />
                  )}
                />
                <ActionIcon
                  size="lg"
                  variant="filled"
                  color="dark"
                  onClick={() => showModal(ProjectModal, {
                    requestId,
                    onSubmit: (project) => pageActions
                      .load()
                      .update({ projectId: project.id })
                      .replace()
                  })}
                >
                  <Plus />
                </ActionIcon>
              </div>
            </div>
          }
        />
      }
    >
      <TagFilters
        value={values.project_tag}
        onChange={(project_tag) => formik.setValues({ project_tag, project_search: "" })}
        options={Object.values(PROJECT_STATUSES).map(((value) => ({
          value,
          label: t(value),
          color: STATUS_COLORS[value]
        })))}
      />
      <AppTable
        loading={loading && _.isEmpty(filteredList)}
        rows={filteredList}
        onRowClick={({ id }) => {
          pageActions
            .load("TimesheetList")
            .update({ projectId: id })
            .replace()
        }}
        columns={[
          {
            id: "name",
            flex: 2,
            render: (project) => {
              const { name, description } = project
              const showClient = compact && !requestId
              return (
                <div className={styles.name}>
                  <div className={styles.row}>
                    <Title order={6} className="elipsis">
                      {name}
                    </Title>
                  </div>
                  {!showClient && <Text size="xs" color="gray" className="elipsis">{description}</Text> }
                  {showClient && <ClientLink project={project} />}
                </div>
              )
            }
          },
          (!compact && !requestId) && {
            id: "client",
            render: (project) => <ClientLink project={project} />
          },
          {
            id: "status",
            justifyContent: "center",
            render: ({ status }) => <StatusBadge status={status} compact={compact} />
          },
          {
            id: "actions",
            flex: "none",
            render: ({ id }) => (
              <ActionIcon
                onClick={(event) => {
                  event.stopPropagation()
                  showModal(ProjectModal, { projectId: id })
                }}
              >
                <Pencil />
              </ActionIcon>
            )
          }
        ].filter(Boolean)}
      />
    </AppPage>
  )
}

function ClientLink({ project }) {
  const { provider_client } = project || {}
  const { client, request } = provider_client || {}
  const info = getUserInfo(client)
  const { id } = request || {}

  if (!id) return null
  return (
    <Button
      size="xs"
      variant="subtle"
      compact
      color="gray"
      leftIcon={<User size="1rem" />}
      className={styles.clientLink}
      classNames={{ leftIcon: styles.leftIcon }}
      onClick={(event) => {
        event.stopPropagation()
        showModal(ClientModal, { requestId: id })
      }}
    >
      {info.name}
    </Button>
  )
}


export default ProjectList
