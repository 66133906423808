import { Avatar, Card } from "@mantine/core"
import { getUserInfo, selectCurrentUser, useIsAuthenticated, useIsProvider } from "modules/auth"
import { t } from "i18next"
import React from "react"
import { Folders, Messages, Plus, Users, Scale, Login, User } from "tabler-icons-react"
import AppLink from "components/AppLink"
import styles from "./index.module.scss"
import LoginModal from "modules/auth/LoginModal"
import { showModal } from "core/modals"
import { showRequestCreate } from "modules/requests"
import pageActions from "core/pages"
import UserModal from "modules/auth/UserModal"
import { useSelector } from "lodash-redux"


function AppFooter() {
  const isProvider = useIsProvider()
  const authenticated = useIsAuthenticated()
  const user = useSelector(() => selectCurrentUser())
  const { initials, photoUrl } = getUserInfo(user)
  const position = "top"

  return (
    <Card className={styles.appFooter}>
      {!isProvider &&
        <>
          <AppLink
            position={position}
            radius="50%"
            variant="filled"
            color="primary"
            icon={<Plus />}
            onClick={() => showRequestCreate()}
            label={t("contact_provider")}
          />
          <AppLink
            position={position}
            icon={<Scale />}
            to={pageActions.load("ProviderList").path}
            label={t("providers")}
          />
        </>
      }
      {authenticated &&
        <>
          {!isProvider &&
            <>
              <AppLink
                position={position}
                icon={<Messages />}
                to={pageActions.load("RequestList").path}
                label={t("chat")}
              />
            </>
          }
          {isProvider &&
            <>
              <AppLink
                position={position}
                icon={<Users />}
                to={pageActions.load("RequestList").path}
                label={t("clients")}
              />
              <AppLink
                position={position}
                icon={<Folders />}
                to={pageActions.load("ProjectList").path}
                label={t("projects")}
              />
            </>
          }
          <Avatar
            radius="50%"
            variant="light"
            src={photoUrl}
            className={styles.avatar}
            onClick={() => showModal(UserModal)}
            style={{ cursor: "pointer" }}
          >
            {initials || <User />}
          </Avatar>
        </>
      }
      {!authenticated &&
        <Avatar
          radius="50%"
          variant="light"
          className={styles.avatar}
          onClick={() => showModal(LoginModal)}
          style={{ cursor: "pointer" }}
        >
          <User />
        </Avatar>
      }
    </Card>
  )
}

export default AppFooter
