import { actions } from "core/store"
import React from "react"
import { useSelector } from "lodash-redux"
import { EMPTY_OBJECT } from "core"


export const selectModal = () => actions.get("modal", EMPTY_OBJECT)
export const showModal = (Component, props) => setTimeout(() => actions.set("modal", { Component, props }))
export const hideModal = () => setTimeout(() => actions.unset("modal"))

export const modalPromise = (Modal, props) => new Promise((resolve, reject) => {
  showModal(Modal, {
    ...props,
    onSubmit: (values) => {
      hideModal()
      resolve(values)
    },
    onDismiss: () => {
      hideModal()
      const confirmError = new Error("action_was_canceled")
      confirmError.confirm = true
      reject(confirmError)
    }
  })
})


export function ModalDispatcher() {
  const { Component, props } = useSelector(() => selectModal())
  const { onClose = hideModal } = props || {}
  if (!Component) return null
  return <Component {...props} onClose={onClose} />
}

