import React from "react"
import join from "clsx"
import styles from "./index.module.scss"
import { Loader } from "@mantine/core"
import { actions } from "core/store"
import { PageContext } from "core/pages"


function AppPage({
  loading,
  children,
  footer,
  className,
  header,
  onScroll,
  width,
  flex
}) {
  const { compact } = React.useContext(PageContext)
  const [ref, setRef] = React.useState()
  React.useEffect(() => {
    if (ref) ref.scrollIntoView(0)
  }, [ref])

  const hasFooter = !!footer
  React.useEffect(() => {
    if (hasFooter) actions.set("hasFooter", true)
    return () => actions.unset("hasFooter")
  }, [hasFooter])

  return (
    <div
      className={join(
        styles.appPage,
        compact && styles.compact,
        loading && styles.loading,
        className
      )}
    >
      <div ref={(node) => setRef(node)} />
      {header}
      <div className={styles.content} onScroll={onScroll} style={{ width, flex }}>
        {!loading && <>{children}</>}
        {loading &&
          <div className="centered-content" >
            <Loader />
          </div>
        }
      </div>
      {footer}
    </div>
  )
}

export default React.memo(AppPage)
