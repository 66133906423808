import { Button, Textarea, TextInput } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import {
  ArrowRight,
  Mail,
  User
} from "tabler-icons-react"
import { useFormik } from "formik"
import { createClientRequest } from ".."
import { setAlertError, setAlertSuccess } from "core/alerts"
import * as yup from "yup"
import AppModal from "components/AppModal"
import { hideModal } from "core/modals"
import _ from "lodash"


function InviteModal({ onSubmit = _.noop }) {
  const { t } = useTranslation()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      text: ""
    },
    validationSchema: yup.object({
      name: yup.string().required(t("field_is_required")),
      email: yup.string().email().required(t("field_is_required")),
      text: yup.string().required(t("field_is_required"))
    }),
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        const request = await createClientRequest(values)
        hideModal()
        setAlertSuccess(t("invite_succesfully_sent"))
        await onSubmit(request)
      } catch (error) {
        setAlertError(error.message)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { values, errors, touched } = formik
  const { name, email, text } = values

  return (
    <AppModal
      className={styles.inviteModal}
      name={t("new_client")}
      footer={
        <Button
          radius="lg"
          color="dark"
          className={styles.button}
          leftIcon={<ArrowRight />}
          onClick={formik.handleSubmit}
          loading={formik.isSubmitting}
          disabled={!formik.dirty}
        >
          {t("send_invite")}
        </Button>
      }
    >
      <TextInput
        radius={0}
        variant="filled"
        label={t("name")}
        name="name"
        value={name}
        onChange={formik.handleChange}
        icon={<User />}
        error={touched.name && errors.name}
      />
      <TextInput
        radius={0}
        variant="filled"
        label={t("email")}
        name="email"
        value={email}
        onChange={formik.handleChange}
        icon={<Mail />}
        error={touched.email && errors.email}
      />
      <Textarea
        label={t("message")}
        radius={0}
        variant="filled"
        name="text"
        description={t("this_will_be_sent_to_the_customer")}
        value={text}
        onChange={formik.handleChange}
        maxLength={2000}
        minRows={3}
        className={styles.textarea}
        error={touched.text && errors.text}
      />
    </AppModal>
  )
}

export default InviteModal
