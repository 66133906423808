import axios from "core/axios"
import { actions } from "core/store"
import { useData } from "core/data"
import { clearLoader, setLoader } from "core/loaders"
import cleanDeep from "clean-deep"
import { EMPTY_OBJECT } from "core"
import _ from "lodash"
import { fetchCurrentUser } from "modules/auth"


const POPULATE = ["file", "request", "message"]
const CLIENT_POPULATE = ["document.file", "document.request", "document.message"]

export const FILES_QUOTA_SIZE = 1
export const FILES_PRICE = 1

const selectDocument = (id) => actions.get(`documents.${id}`)
export const selectDocuments = () => actions.get("documents", EMPTY_OBJECT)

export const useDocuments = (params, deps) => {
  return useData("documents", fetchDocuments, params, deps)
}

const fetchDocuments = async (params) => {
  const { data = [] } = await axios.get("/client-documents", {
    params: {
      populate: CLIENT_POPULATE,
      ...params
    }
  })
  const items = data
    .filter(({ document }) => !_.isEmpty(document))
    .reduce((acc, item) => {
      const { id, document } = item
      acc[document.id] = { ...document, clientDocumentId: id }
      return acc
    }, {})
  actions.update("documents", items)
  return items
}

export const createDocument = async ({ file }) => {
  const formData = new FormData()
  if (file) formData.append("files.file", file, file.name)
  formData.append("data", JSON.stringify({}))
  const { data: item } = await axios.post("/documents", formData, {
    params: { populate: POPULATE }
  })
  actions.set(`documents.${item.id}`, item)
  fetchCurrentUser()
  return item

}

export const deleteDocument = async (id) => {
  try {
    setLoader(`deleteDocument.${id}`)
    const { clientDocumentId } = selectDocument(id)
    await axios.delete(`/client-documents/${clientDocumentId}`)
    fetchCurrentUser()
    actions.unset(`documents.${id}`)
  } finally {
    clearLoader(`deleteDocument.${id}`)
  }
}


export const fetchDocumentPaymentUrl = async ({ quantity }) => {
  try {
    setLoader("fetchDocumentPaymentUrl")
    const data = await axios.post("/increase-quota-payment-link", {
      data: cleanDeep({
        quantity,
        payment_return_url: window.location.href
      })
    })

    actions.set("paymentUrl", data)
    return data
  } finally {
    clearLoader("fetchDocumentPaymentUrl")
  }
}

export const documentActions = actions.create("layout.documents")