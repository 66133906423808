import { List, Text, Title } from "@mantine/core"
import { t } from "i18next"
import React from "react"
import styles from "./index.module.scss"
import AppCard from "components/AppCard"
import { getYearRange } from "modules/providers"
import _ from "lodash"
import IconText from "components/IconText"


function JobsCard({ provider }) {
  const { profile } = provider || {}
  let { jobs = [] } = profile || {}
  jobs = _.castArray(jobs)

  if (_.isEmpty(jobs)) return null
  return (
    <AppCard name={t("jobs")}>
      <List
        spacing="xs"
        size="sm"
        listStyleType="none"
      >
        {jobs.map((job) => {
          const { id, name, address, description } = job
          const yearRange = getYearRange(job)
          return (
            <List.Item
              key={id}
              className={styles.jobItem}
            >
              <Title order={5}>{name}</Title>
              <Text size="sm">{description}</Text>
              <Text size="sm">{yearRange}</Text>
              <IconText value={address} />
            </List.Item>
          )
        })}
      </List>
    </AppCard>
  )
}


export default React.memo(JobsCard)
