import { Button, Input, PasswordInput } from "@mantine/core"
import cleanDeep from "clean-deep"
import AppCard from "components/AppCard"
import PageFooter from "components/PageFooter"
import { setAlertSuccess } from "core/alerts"
import { updateUser } from "modules/auth"
import { useFormik } from "formik"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { Lock } from "tabler-icons-react"
import styles from "./index.module.scss"
import set from "lodash/fp/set"
import unset from "lodash/fp/unset"


function PasswordSection({ name, setFooters }) {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: ""
    },
    validate: ({ newPassword, confirmPassword }) => {
      let errors = {}
      if (newPassword && newPassword !== confirmPassword) {
        errors.newPassword = t("passwords_do_not_match")
      }
      if (!newPassword) errors.newPassword = t("password_is_required")
      return errors
    },
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        const { newPassword } = values
        await updateUser({ password: newPassword })
        setAlertSuccess(t("password_changed_succesfully"))
        formik.resetForm()
      } catch (error) {
        formik.setFieldError("password", t(error.message))
      } finally {
        formik.setSubmitting(false)
      }
    }
  })
  const { errors, values, touched } = formik
  const { newPassword, confirmPassword } = values
  const isTouched = !_.isEmpty(cleanDeep(touched))

  React.useEffect(() => {
    if (formik.dirty) {
      setFooters((footers) => set(name, (
        <PageFooter>
          <Button
            radius="lg"
            variant="outline"
            color="dark"
            onClick={formik.resetForm}
          >
            {t("cancel")}
          </Button>
          <Button
            leftIcon={<Lock />}
            loading={formik.isSubmitting}
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
            type="submit"
            color="dark"
            radius="lg"
          >
            {t("change_password")}
          </Button>
        </PageFooter>
      ), footers))
    } else {
      setFooters((footers) => unset(name, footers))
    }
  }, [formik.dirty])


  return (
    <AppCard
      className={styles.passwordSection}
      name={t("password")}
      component="form"
      autoComplete="off"
    >
      <Input.Wrapper
        className={styles.field}
        label={t("change_password")}
      >
        <div className={styles.content}>
          <PasswordInput
            radius={0}
            variant="filled"
            name="newPassword"
            value={newPassword}
            onChange={formik.handleChange}
            placeholder={t("new_password")}
            error={isTouched && !_.isEmpty(errors)}
          />
          <PasswordInput
            radius={0}
            variant="filled"
            name="confirmPassword"
            value={confirmPassword}
            onChange={formik.handleChange}
            placeholder={t("confirm_password")}
            error={isTouched && errors.newPassword}
          />
          <div />

        </div>
      </Input.Wrapper>
    </AppCard>
  )
}

export default React.memo(PasswordSection)
