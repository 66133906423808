import { Badge, Chip } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"
import join from "clsx"
import styles from "./index.module.scss"
import { PageContext } from "core/pages"
import _ from "lodash"


function TagFilters({ value = "", options, onChange, className }) {
  const { t } = useTranslation()
  const { compact } = React.useContext(PageContext)

  return (
    <div className={join(styles.tagFilters, compact && styles.compact, className)}>
      <Badge
        variant={!value ? "filled" : undefined}
        color={!value ? "dark" : "gray"}
        radius="lg"
        className={join(styles.tag, !value && styles.selected)}
        onClick={() => onChange("")}
      >
        {t("all")}
      </Badge>
      {options.map((option) => {
        const selected = option.value === value
        return (
          <Badge
            key={option.value}
            variant={selected ? "filled" : undefined}
            color={selected ? _.defaultTo(option.color, "dark") : "gray"}
            radius="lg"
            className={join(styles.tag, selected && styles.selected)}
            onClick={() => {
              if (selected) {
                onChange("")
              } else {
                onChange(option.value)
              }
            }}
          >
            {option.label}
          </Badge>
        )
      })}
      <div style={{ flex: 999 }} />
    </div>
  )
}


export default TagFilters
