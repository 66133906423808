import { Button, Select, Textarea, TextInput } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import {
  DeviceFloppy,
  Folder
} from "tabler-icons-react"
import { useFormik } from "formik"
import { setAlertError, setAlertSuccess } from "core/alerts"
import * as yup from "yup"
import AppModal from "components/AppModal"
import { hideModal } from "core/modals"
import _ from "lodash"
import { createProject, PROJECT_STATUSES, updateProject, useProject } from ".."
import ClientSelect from "modules/clients/ClientSelect"


const INITIAL_VALUES = {
  name: "",
  description: "",
  requestId: "",
  status: PROJECT_STATUSES.draft
}


function ProjectModal({ projectId, requestId = "", onSubmit = _.noop }) {
  const { t } = useTranslation()
  const [project] = useProject(projectId)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: _.pick({
      ...INITIAL_VALUES,
      requestId,
      ...project
    }, Object.keys(INITIAL_VALUES)),
    validationSchema: yup.object({
      name: yup.string().required(t("field_is_required"))
    }),
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        let project
        if (projectId) {
          project = await updateProject(projectId, values)
        } else {
          project = await createProject(values)
        }
        await onSubmit(project)
        hideModal()
        setAlertSuccess(t("project_succesfully_created"))
      } catch (error) {
        setAlertError(error.message)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { values, errors, touched } = formik
  const { name, description, status } = values

  return (
    <AppModal
      className={styles.projectModal}
      icon={<Folder />}
      name={projectId ? t("edit_project") : t("new_project")}
      footer={
        <>
          <Button
            radius="lg"
            color="dark"
            className={styles.button}
            leftIcon={<DeviceFloppy />}
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
            disabled={!formik.dirty}
          >
            {t("save")}
          </Button>
        </>
      }
    >
      <TextInput
        variant="filled"
        label={t("name")}
        name="name"
        value={name}
        onChange={formik.handleChange}
        icon={<Folder />}
        error={touched.name && errors.name}
      />
      <Textarea
        variant="filled"
        label={t("description")}
        name="description"
        value={description}
        maxLength={2000}
        minRows={5}
        onChange={formik.handleChange}
        error={touched.description && errors.description}
      />
      <ClientSelect
        readOnly={requestId}
        value={values.requestId}
        onChange={(id) => formik.setFieldValue("requestId", id)}
      />
      <Select
        variant="filled"
        withinPortal
        style={{ maxWidth: "10rem" }}
        label={t("status")}
        value={status}
        onChange={(value) => formik.setFieldValue("status", value)}
        data={Object.values(PROJECT_STATUSES).map((value) => ({
          value,
          label: t(value)
        }))}
      />
    </AppModal>
  )
}

export default ProjectModal
