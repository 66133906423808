import { ActionIcon, Card, Popover, Text, Title } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { ArrowLeft, DotsVertical } from "tabler-icons-react"
import join from "clsx"
import _ from "lodash"
import { PageContext } from "core/pages"


function PageHeader({
  name,
  icon,
  description,
  toolbar,
  label,
  onClick,
  className,
  badge,
  menu,
  extra,
  hiddenBack,
  onBack
}) {
  const { compact } = React.useContext(PageContext)
  const [opened, setOpened] = React.useState(false)

  return (
    <Card
      radius={0}
      className={join(
        styles.pageHeader,
        compact && styles.compact,
        onClick && styles.clickable,
        className
      )}
    >
      <div className={styles.top}>
        {onBack && !hiddenBack &&
          <ActionIcon
            color="primary"
            onClick={onBack}
            className={styles.back}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionIcon>
        }
        {icon &&
          <div className={styles.icon}>
            {icon}
          </div>
        }
        {(name || badge) &&
          <div className={styles.header} onClick={onClick}>
            {label &&
              <Text
                color="gray"
                size="xs"
                className="elipsis"
                style={{ lineHeight: 1 }}
              >
                {label}
              </Text>
            }
            {name &&
              <Title
                order={description ? 5 : 4}
                className="elipsis"
              >
                {name}
              </Title>
            }
            <Text color="gray" size="xs" className="elipsis">
              {description}
            </Text>
          </div>
        }
        <div style={{ flex: 1 }} />
        {extra &&
          <div className={styles.extra}>
            {extra}
          </div>
        }
        {menu && !_.isEmpty(menu) &&
          <Popover
            opened={opened}
            onChange={setOpened}
            position="bottom-end"
            withArrow
            arrowSize={12}
            arrowOffset=".5rem"
            shadow="md"
            withinPortal
          >
            <Popover.Target>
              <ActionIcon onClick={() => setOpened(!opened)} variant="subtle">
                <DotsVertical />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown className={styles.dropdown}>
              <div className={styles.menu} onClick={() => setOpened(false)}>
                {menu}
              </div>
            </Popover.Dropdown>
          </Popover>
        }
      </div>
      {toolbar && <div className={styles.toolbar}>{toolbar}</div> }
    </Card>
  )
}

export default React.memo(PageHeader)