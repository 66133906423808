import { Anchor, List, Text } from "@mantine/core"
import AppCard from "components/AppCard"
import React from "react"
import { useTranslation } from "react-i18next"
import { Clock, Heart, ThumbUp } from "tabler-icons-react"
import styles from "./index.module.scss"


function ActivityCard({ provider }) {
  const { t } = useTranslation()
  const { rating = 0, customerCount = 0, reviews = [], responseTime = 1 } = provider || {}

  return (
    <AppCard name={t("activity")}>
      <List
        spacing="xs"
        size="sm"
      >
        <List.Item
          icon={<ThumbUp size="1.5em" />}
          className={styles.activityItem}
        >
          <div className={styles.row}>
            <Text size="sm">{t("satisfied_customers")}</Text>
            <Text size="sm" weight="bold">{`${rating}%`}</Text>
          </div>
        </List.Item>
        <List.Item
          icon={<Heart size="1.5em" />}
          className={styles.activityItem}
        >
          <div className={styles.row}>
            <Text size="sm">{t("helped")}</Text>
            <Text size="sm" weight="bold">{customerCount}</Text>
            <Text size="sm">{t("people")}</Text>
          </div>
        </List.Item>
        <List.Item
          icon={<Heart size="1.5em" />}
          className={styles.activityItem}
        >
          <Anchor variant="" size="sm" color="blue">
            {`${reviews.length} ${t("reviews")}`}
          </Anchor>
        </List.Item>
        <List.Item
          icon={<Clock size="1.5em" />}
          className={styles.activityItem}
        >
          <div className={styles.row}>
            <Text size="sm">{t("response_time")}</Text>
            <Text size="sm" weight="bold">{`${responseTime - 1}-${responseTime + 1}`}</Text>
            <Text size="sm">{t("hours")}</Text>
            <Text size="sm">{`(${t("estimated")})`}</Text>
          </div>
        </List.Item>
      </List>
    </AppCard>
  )
}

export default ActivityCard
