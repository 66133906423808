import axios, { API_URL, debouncedLogout, NO_AUTHORIZATION } from "core/axios"
import { actions } from "core/store"
import history from "core/history"
import { useSelector } from "lodash-redux"
import _ from "lodash"
import i18n, { initLocale } from "core/i18n"
import { clearLoader, setLoader } from "core/loaders"
import { showModal } from "core/modals"
import ResetModal from "./ResetModal"
import React from "react"
import { requestActions, showRequestCreate } from "modules/requests"
import { setAlertSuccess } from "core/alerts"
import LoginModal from "./LoginModal"
import qs from "qs"
import { documentActions } from "modules/documents"
import DocumentModal from "modules/documents/DocumentModal"
import jwtDecode from "jwt-decode"

const EMPTY_OBJECT = {}
export const USER_TYPES = {
  PROVIDER: "provider",
  CLIENT: "client"
}

const POPULATE_USER = [
  "profile",
  "profile.photo",
  "notifications"
]

export const selectAuth = () => actions.get("auth", EMPTY_OBJECT)
export const selectCurrentUser = () => actions.get("auth.user", EMPTY_OBJECT)
export const selectToken = () => actions.get("auth.token")

export const useIsProvider = () => {
  const { user } = useSelector(() => selectAuth())
  const userType = getUserType(user)
  const isProvider = userType === USER_TYPES.PROVIDER
  return isProvider
}

export const useIsClient = () => {
  const user = useSelector(() => selectCurrentUser())
  const userType = getUserType(user)
  const isClient = useSelector(() => !userType || userType === USER_TYPES.CLIENT)
  return isClient
}

const getUserType = (user) => _.get(user, "user_type")

export const useIsAuthenticated = () => {
  const isAuthenticated = useSelector(() => !!selectToken())
  return isAuthenticated
}


export const initAuth = async () => {
  try {
    const { pathname, search } = window.location
    const { code, confirmed, disableAuthProviders } = qs.parse(search, { ignoreQueryPrefix: true })
    if (disableAuthProviders) actions.set("disableAuthProviders", true) // will be persisted
    const pendingLogin = requestActions.get("pendingLogin")
    const pendingPayment = documentActions.get("pendingPayment")

    const config = {
      headers: {
        authorization: NO_AUTHORIZATION
      }
    }

    switch (pathname) {
      case ("/auth/apple/callback"): {
        const data = await axios.get(`/auth/apple/callback${search}`, config)
        const token = getToken(data)
        setToken(token)
        break
      }
      case ("/auth/google/callback"): {
        const data = await axios.get(`/auth/google/callback${search}`, config)
        const token = getToken(data)
        setToken(token)
        break
      }
      case ("/auth/facebook/callback"): {
        const data = await axios.get(`/auth/facebook/callback${search}`, config)
        const token = getToken(data)
        setToken(token)
        break
      }
      case ("/reset-password"): {
        alert(code)
        showModal(ResetModal, { code })
        break
      }
      default: // do nothing
    }

    const token = selectToken()
    if (token) await fetchCurrentUser()
    await initLocale()

    switch (true) {
      case (Boolean(!token && pathname === "/login")): {
        history.replace("/")
        showModal(LoginModal)
        break
      }
      case (Boolean(!token && confirmed)): {
        setAlertSuccess(i18n.t("email_succesfully_confirmed"))
        actions.unset("registerEmail")
        history.replace("/")
        showModal(LoginModal)
        break
      }
      case (Boolean(pendingLogin && token)): {
        requestActions.unset("pendingLogin")
        const params = requestActions.get()
        showRequestCreate(params)
        break
      }

      case (Boolean(pendingPayment && token)): {
        documentActions.unset("pendingPayment")
        showModal(DocumentModal)
        break
      }
      default: // do nothing
    }
  } catch {
    await debouncedLogout()
  }
}


export const googleLogin = () => {
  setTimeout(() => {
    window.open(`${API_URL}/connect/google`, "_self")
  })
}


export const facebookLogin = () => {
  setTimeout(() => {
    window.open(`${API_URL}/connect/facebook`, "_self")
  })
}

export const appleLogin = () => {
  setTimeout(() => {
    window.open(`${API_URL}/connect/apple`, "_self")
  })
}


export const emailLogin = async ({ email, password }) => {
  const data = await axios.post("/auth/local", { identifier: email, password })
  initUserData(data)
}


export const emailRegister = async ({ username, email, password }) => {
  await axios.post("/auth/local/register", {
    username,
    email,
    password,
    profile: { name: username }
  })
}


export const emailRecover = async ({ email }) => {
  await axios.post("/auth/forgot-password", { email })
}


export const passwordReset = async ({ code, password, passwordConfirmation }) => {
  const data = await axios.post("/auth/reset-password", {
    code,
    password,
    passwordConfirmation
  })
  initUserData(data)
}


export const fetchCurrentUser = async () => {
  const user = await axios.get("/users/me", {
    params: {
      populate: POPULATE_USER
    }
  })
  actions.set("auth.user", user)
}


export const deleteAccount = async () => {
  try {
    setLoader("deleting_account")
    await axios.delete("/delete-account")
    await debouncedLogout()
  } finally {
    clearLoader("deleting_account")
  }

}


export const updateUser = async (values) => {
  const currentUser = selectCurrentUser()
  actions.update("auth.user", values)
  try {
    setLoader("updateUser")
    const { id } = currentUser
    if (id) {
      await axios.put(`/users/${id}`, values, { params: { populate: POPULATE_USER } })
    }
  } catch (error) {
    actions.set("auth.user", currentUser)
    throw error
  } finally {
    clearLoader("updateUser")
  }
}

export const logout = () => new Promise(() => {
  setLoader("reloading")
  actions.update((state) => _.pick(state, [
    "loaders",
    "language",
    "providers",
    "disableAuthProviders",
    "context.breakpoints"
    // TODO: when clearing the state make sure you don't clear everything
  ]))
  setTimeout(() => {
    history.replace("/")
    window.location.reload()
  }, 500)
})

export const usePushNotifications = () => {
  const { id } = useSelector(() => selectCurrentUser())
  React.useEffect(() => {
    if (id) window.postMessage(JSON.stringify({
      userLoggedIn: true
    }))
  }, [id])

  React.useEffect(() => {
    const listener = async (event) => {
      try {
        const { data } = event || {}
        const { fcmToken } = data && JSON.parse(data)
        if (fcmToken) {
          await updateUser({ mobile_firebase_id: fcmToken })
        }
      } catch {
        // do nothing
      }
    }
    window.addEventListener("message", listener)
    return () => window.removeEventListener("message", listener)
  }, [])
}

export const getUserInfo = (user) => {
  const type = getUserType(user)
  const { username, email, profile } = user || {}
  const { name, title, photo } = profile || {}
  const { url } = photo || {}

  const _name = name || username || email || ""
  const _description = type === USER_TYPES.CLIENT ? email : title

  return {
    name: _name,
    description: _description,
    photoUrl: url,
    initials: getInitials(_name),
    email
  }
}

const initUserData = (data) => {
  const token = getToken(data)
  setToken(token)
  fetchCurrentUser()
  initLocale()

  setLoader("reloading")
  setTimeout(() => {
    history.replace("/")
    window.location.reload()
  }, 350)
}

const getToken = (data) => _.get(data, "jwt")
export const getInitials = (_name = "") => {
  let words = _name.split(" ")
  if (words.length < 2) words = _name.split(".")
  if (words.length < 2) return _name.slice(0, 2).toUpperCase()
  const initials = words
    .slice(0, 2)
    .map((word) => word.slice(0, 1))
    .join("")
    .toUpperCase()

  return initials
}

const setToken = (token) => {
  const { exp } = jwtDecode(token)
  actions.update("auth", { token, expireAt: exp * 1000 })
}