
import axios from "core/axios"
import { clearLoader, setLoader } from "core/loaders"
import { actions } from "core/store"
import _ from "lodash"
import cleanDeep from "clean-deep"
import { useItem, useData } from "core/data"
import { EMPTY_OBJECT } from "core"
import { selectRequest } from "modules/requests"


const POPULATE = [
  "provider_client.request",
  "provider_client.client",
  "provider_client.client.profile.photo"
]

export const PROJECT_STATUSES = {
  draft: "draft",
  in_progress: "in_progress",
  closed: "closed"
}


const mapProject = (data = {}) => {
  const { id, status = PROJECT_STATUSES.draft, provider_client = {}, ...rest } = data
  const { request = {} } = provider_client
  const { id: requestId } = request
  const project = {
    ...rest,
    provider_client,
    id: id,
    requestId,
    status
  }

  return project
}

export const useProjects = (params = {}, deps = []) => {
  return useData("projects", fetchProjects, params, deps)
}
const fetchProjects = async (params) => {
  const { populate, ...query } = params || {}
  const { data = [] } = await axios.get("/projects", { // TODO: when adding pagination sort should be made using api
    params: cleanDeep({
      populate: populate || POPULATE,
      ...query
    })
  })

  const items = data.reduce((acc, item) => {
    acc[item.id] = mapProject(item)
    return acc
  }, {})

  actions.update("projects", items)
  return items
}

export const useProject = (id, ...args) => useItem(id, "projects", fetchProject, ...args)
export const selectProject = (id) => actions.get(`projects.${id}`, EMPTY_OBJECT)

const fetchProject = async (id, callback = _.noop) => {
  try {
    const { data } = await axios.get(`/projects/${id}`, {
      params: { populate: POPULATE }
    })
    const item = mapProject(data)
    await callback(undefined, item)
    actions.set(`projects.${id}`, item)
    return item
  } catch (error) {
    await callback(error)
    throw error
  }
}

export const createProject = async (values) => {
  try {
    setLoader("createProject")
    const { name, price_per_hour, requestId, ...rest } = values
    const request = selectRequest(requestId)
    const { provider_client = {} } = request
    const { data } = await axios.post("/projects", {
      data: cleanDeep({
        ...rest,
        name,
        price_per_hour: Number(price_per_hour),
        provider_client: provider_client.id
      })
    }, { params: { populate: POPULATE } })
    const item = mapProject(data)
    actions.set(`projects.${item.id}`, item)
    return item
  } finally {
    clearLoader("creatingProject")
  }
}

export const updateProject = async (id, values) => {
  const { name, price_per_hour, requestId, ...rest } = values
  const request = selectRequest(requestId)
  const { provider_client = {} } = request
  const { data } = await axios.put(`/projects/${id}`, {
    data: cleanDeep({
      ...rest,
      name,
      price_per_hour: Number(price_per_hour),
      provider_client: provider_client.id
    })
  }, { params: { populate: POPULATE } })
  const item = mapProject(data)
  actions.set(`projects.${id}`, item)
  return item
}


export const viewActions = actions.create("layout.projectView")