import { ActionIcon, Tooltip } from "@mantine/core"
import React from "react"
import { NavLink } from "react-router-dom"
import styles from "./index.module.scss"


function AppLink({ icon, label, to, loading, disabled, position = "right", ...props }) {
  const element = (to && !disabled)
    ? <NavLink to={to} className={styles.appLink} activeClassName={styles.active} />
    : <div className={styles.appLink} />

  return (
    React.cloneElement(element, props, (
      <Tooltip
        label={label}
        position={position}
        withArrow
        arrowSize={7}
      >
        <ActionIcon
          size="md"
          radius="md"
          disabled={disabled}
          loading={loading}
          variant="subtle"
          className={styles.icon}
          {...props}
        >
          {React.cloneElement(icon)}
        </ActionIcon>
      </Tooltip>
    ))
  )
}

export default React.memo(AppLink)
