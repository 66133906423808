import { Avatar, Badge, Card, Indicator, Text, Title, Tooltip } from "@mantine/core"
import React from "react"
import join from "clsx"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { STATUS_COLORS } from "core"
import { Link } from "react-router-dom"
import IconText from "components/IconText"
import { CreditCard } from "tabler-icons-react"
import pageActions, { PageContext } from "core/pages"


function ProviderCard({
  provider,
  selected,
  actions,
  specializationId,
  onSpecializationClick = _.noop,
  ...props
}) {
  const { compact } = React.useContext(PageContext)
  const { i18n, t } = useTranslation()
  const formater = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "RON",
    maximumSignificantDigits: 3
  })


  const { profile, status, specializations = [], initial_service_price = 89 } = provider || {}
  const { name, title, photo, description, address } = profile || {}
  const { city, country } = address || {}
  const { url: photoUrl } = photo || {}
  const path = pageActions
    .load("ProviderView")
    .update({ providerId: provider.id })
    .path

  if (_.isEmpty(provider)) return null
  return (
    <Card
      {...props}
      radius={compact ? 0 : "md"}
      className={join(
        styles.providerCard,
        compact && styles.compact,
        selected && styles.selected
      )}
    >
      <div className={styles.top}>
        <div className={styles.photo}>
          <Avatar
            className={styles.avatar}
            src={photoUrl}
            alt="photoUrl"
            variant="filled"
            component={Link}
            to={path}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>
            <Title
              order={4}
              lineClamp={1}
              color="dark"
              underline
              component={Link}
              to={path}
            >
              {name}
            </Title>
            <Tooltip
              label={t(`provider_${status}`)}
              position="right"
            >
              <Indicator
                position="middle-end"
                offset={-10}
                color={STATUS_COLORS[status]}
                zIndex={0}
              />
            </Tooltip>
          </div>
          <Text size="sm" color="gray" weight="bold">{title}</Text>
          <div className={styles.info}>
            <IconText
              className={styles.address}
              value={[city, country].filter(Boolean).join(", ")}
            />
            <IconText
              icon={<CreditCard />}
              value={
                <>
                  {`${t("starting_price")}:`}
                  {" "}
                  <Text span weight="bold" color="gray">
                    {`${formater.format(initial_service_price)}`}
                  </Text>
                </>
              }
            />
          </div>

        </div>
      </div>

      <div className={styles.specializations}>
        {specializations.map(({ id, name }) => {
          const selected = specializationId === id
          return (
            <Badge
              key={name}
              variant={selected ? "filled" : undefined}
              color={selected ? "dark" : "gray"}
              radius="lg"
              className={join(styles.specialization, selected && styles.selectedSpecialization)}
              size="sm"
              onClick={(event) => {
                event.stopPropagation()
                onSpecializationClick(id)
                setTimeout(() => {
                  event.target.scrollIntoView({ block: "center" })
                })
              }}
            >
              {t(name)}
            </Badge>
          )
        })}
      </div>
      <div className={styles.description}>
        <Text size="xs" lineClamp={2}>{description}</Text>
        <Text
          weight="bold"
          color="blue"
          size="xs"
          component={Link}
          to={path}
        >
          {t("show_more")}
        </Text>
      </div>
      {actions &&
        <div className={styles.actions} onClick={(event) => event.stopPropagation()}>
          {actions}
        </div>
      }

    </Card>
  )
}

export default React.memo(ProviderCard)
