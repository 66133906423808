import { Button, Input, Text } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { CreditCard } from "tabler-icons-react"
import { documentActions, fetchDocumentPaymentUrl, FILES_PRICE, FILES_QUOTA_SIZE } from "modules/documents"
import _ from "lodash"
import { useBreakpoint } from "core/layout"
import join from "clsx"
import { useLoader } from "core/loaders"
import styles from "./index.module.scss"


function DocumentModal({ min, formik }) {
  const { t, i18n } = useTranslation()
  const loading = useLoader("fetchDocumentPaymentUrl")
  const formater = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "RON",
    maximumSignificantDigits: 3
  })

  const { values } = formik
  const { qty } = values

  const sm = useBreakpoint("sm")
  const roundedMin = getRoundedValue(min)
  React.useEffect(() => {
    formik.setFieldValue("qty", roundedMin)
  }, [roundedMin])

  return (
    <div className={join(styles.filePayment, sm && styles.sm)}>
      <div className={styles.column}>
        <Text size="sm" weight="bold">{t("to_upload_the_selected_documents_dynamic_mb", { value: qty })}</Text>
        <Text size="xs" color="gray">
          {t("buy_more_space_dynamic", { size: FILES_QUOTA_SIZE, price: formater.format(FILES_PRICE) })}
        </Text>
      </div>
      <div className={styles.row}>
        <Button
          leftIcon={<CreditCard />}
          className={styles.button}
          radius="lg"
          color="dark"
          loading={loading}
          onClick={async () => {
            try {
              formik.setSubmitting(true)
              const { paymentLink } = await fetchDocumentPaymentUrl({ quantity: qty })
              documentActions.set("pendingPayment", true)
              const { url } = paymentLink
              window.open(url, "_self")
            } finally {
              formik.setSubmitting(false)
            }
          }}
        >
          {`${t("pay")} ${formater.format(qty * FILES_PRICE)}`}
        </Button>
      </div>
    </div>
  )
}

const getRoundedValue = (value) => _.ceil(value / FILES_QUOTA_SIZE) * FILES_QUOTA_SIZE

export default DocumentModal