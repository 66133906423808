import { Button, PasswordInput, TextInput } from "@mantine/core"
import { emailRegister } from "modules/auth"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { Key, User } from "tabler-icons-react"
import styles from "./index.module.scss"
import _ from "lodash"
import { actions } from "core/store"
import { setAlertSuccess } from "core/alerts"
import AppModal from "components/AppModal"
import { showModal } from "core/modals"
import LoginModal from "../LoginModal"


function RegisterModal({ initialValues }) {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      ...initialValues
    },
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        await emailRegister(values)
        const { email } = values
        actions.set("registerEmail", email) // is persistent
        showModal(LoginModal)
        setAlertSuccess(t("a_confirmation_email_has_been_sent"))
      } catch (error) {
        const { data, message } = error
        if (_.isEmpty(data)) {
          formik.setErrors({ password: message })
        } else {
          const { username, email, password } = data
          formik.setErrors({ username, email, password })
        }
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { errors } = formik
  const { username, email, password } = formik.values

  return (
    <AppModal
      size="sm"
      className={styles.registerModal}
      name={t("register")}
    >
      <TextInput
        radius={0}
        variant="filled"
        label={t("name")}
        name="username"
        value={username}
        onChange={formik.handleChange}
        icon={<User />}
        error={errors.username}
      />
      <TextInput
        radius={0}
        variant="filled"
        label={t("email")}
        name="email"
        value={email}
        onChange={formik.handleChange}
        icon={<User />}
        error={errors.email}
      />
      <PasswordInput
        radius={0}
        variant="filled"
        label={t("password")}
        name="password"
        value={password}
        onChange={formik.handleChange}
        icon={<Key />}
        error={errors.password}
      />
      <div className={styles.divider} />
      <Button
        radius="lg"
        color="dark"
        type="submit"
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
      >
        {t("register")}
      </Button>
      <Button
        radius="lg"
        color="dark"
        variant="outline"
        onClick={() => showModal(LoginModal, {
          initialValues
        })}
      >
        {t("back")}
      </Button>
    </AppModal>
  )
}

export default RegisterModal

