import { Progress, Text } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"


function FileProgress({ upload_total_mb, upload_limit_mb, files_size_mb }) {
  const { t } = useTranslation()

  const total = Math.max(upload_limit_mb, files_size_mb + upload_total_mb)
  const progress = getProgress({ upload_total_mb, upload_limit_mb, files_size_mb })
  const { percent_total, percent_files, percent_available, percent_exceding } = progress

  return (
    <div className={styles.fileProgress}>
      <Text size="sm" weight="bold">
        {!percent_exceding
          ? t("your_storage_is_dynamic", { percent: percent_available.toFixed(2) })
          : t("your_storage_is_full_dynamic", { value: (percent_exceding * total / 100).toFixed(2) })
        }
      </Text>
      <Progress
        size="md"
        sections={[
          {
            value: percent_total,
            color: files_size_mb ? "dark" : "blue"
          },
          {
            value: percent_files,
            color: percent_exceding ? "dark" : "green"
          },
          {
            color: "lightgray",
            value: percent_available
          },
          {
            value: percent_exceding,
            color: "red"
          }
        ].filter(Boolean)}
      />
      <span style={{ lineHeight: "1rem" }}>
        <Text size="xs" color="gray" component="span">
          {t("you_can_upload_up_to_dynamic_amount", { value: (upload_limit_mb - upload_total_mb).toFixed(2) })}
          {". "}
          {t("for_large_files_we_recommend")}
          {" "}
        </Text>
        <Text
          size="xs"
          component="a"
          target="_blank"
          weight="bold"
          href="https://wetransfer.com/"
          color="dark"
        >
          WeTransfer
        </Text>
      </span>

    </div>
  )
}

const getProgress = ({ upload_total_mb, upload_limit_mb, files_size_mb }) => {
  if (upload_limit_mb < files_size_mb + upload_total_mb) {
    const denominator = (upload_total_mb + files_size_mb) / 100

    const percent_total = upload_limit_mb / denominator
    const percent_files = 0
    const percent_available = 0
    const percent_exceding = (files_size_mb + upload_total_mb - upload_limit_mb) / denominator

    return {
      percent_total,
      percent_files,
      percent_available,
      percent_exceding
    }
  } else {
    const denominator = upload_limit_mb / 100

    const percent_total = upload_total_mb / denominator
    const percent_files = files_size_mb / denominator
    const percent_available = (upload_limit_mb - upload_total_mb - files_size_mb) / denominator
    const percent_exceding = 0

    return {
      percent_total,
      percent_files,
      percent_available,
      percent_exceding
    }
  }
}


export default React.memo(FileProgress)