import axios from "core/axios"
import { actions } from "core/store"
import dayjs from "dayjs"
import _ from "lodash"
import { useData, useItem } from "core/data"
import { EMPTY_OBJECT } from "core"


export const useProviders = (params, deps) => {
  return useData("providers", fetchProviders, params, deps)
}
export const useProvider = (id) => useItem(id, "providers", fetchProviders)
export const selectProvider = (id) => actions.get(`providers.${id}`, EMPTY_OBJECT)

const fetchProviders = async (params) => {
  const data = await axios.get("/providers", {
    params: {
      populate: [
        "available_services",
        "profile.photo",
        "profile.jobs",
        "profile.education",
        "specializations",
        "profile.address"
      ],
      ...params
    }
  }) || []

  const items = data.reduce((acc, item) => {
    const { highlight_position = 0 } = item
    acc[item.id] = { ...item, highlight_position }
    return acc
  }, {})

  actions.update("providers", items)
  return items
}

export const getYearRange = ({ startAt, endAt }) => _.uniq([
  dayjs(startAt).format("MMM YYYY"),
  dayjs(endAt).format("MMM YYYY")
]).join(" - ")

