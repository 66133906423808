import { Button, TextInput, Title } from "@mantine/core"
import { emailRecover } from "modules/auth"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { Mail } from "tabler-icons-react"
import styles from "./index.module.scss"
import _ from "lodash"
import { setAlertSuccess } from "core/alerts"
import AppModal from "components/AppModal"
import { hideModal, showModal } from "core/modals"
import LoginModal from "../LoginModal"


function RecoverModal({ initialValues }) {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      email: "",
      ...initialValues

    },
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        await emailRecover(values)
        setAlertSuccess(t("a_recovery_email_has_been_sent"))
        hideModal()
      } catch (error) {
        const { data, message } = error
        if (_.isEmpty(data)) {
          formik.setErrors({ email: message })
        } else {
          const { email } = data
          formik.setErrors({ email })
        }
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { errors } = formik
  const { email } = formik.values

  return (
    <AppModal
      size="sm"
      className={styles.recoverModal}
      name={t("recover_password")}
    >

      <TextInput
        radius={0}
        variant="filled"
        label={t("email")}
        name="email"
        value={email}
        onChange={formik.handleChange}
        icon={<Mail />}
        error={errors.email}
      />
      <div className={styles.divider} />
      <Button
        radius="lg"
        color="dark"
        type="submit"
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
      >
        {t("send_email")}
      </Button>
      <Button
        radius="lg"
        color="dark"
        variant="outline"
        onClick={() => showModal(LoginModal, { initialValues })}
      >
        {t("back")}
      </Button>
    </AppModal>
  )
}

export default RecoverModal

