import { Button, Text, Badge, ActionIcon, Box, Title } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import { CreditCard } from "tabler-icons-react"
import { useIsProvider } from "modules/auth"
import _ from "lodash"
import { redirectToPaymentUrl } from "modules/requests"
import join from "clsx"
import FileItem from "modules/documents/FileItem"
import { STATUS_COLORS, STATUS_ICONS } from "core"
import { useProtectedMessage } from ".."


function DynamicItem({
  className,
  dynamic,
  status,
  payment_url,
  protected_message,
  disabled,
  current,
  ...props
}) {
  const { t, i18n } = useTranslation()
  const formater = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "RON",
    maximumSignificantDigits: 3
  })
  const isProvider = useIsProvider()
  const { description, price = 0 } = dynamic || {}
  const hasAction = !isProvider && status === "pending"

  const { id: protectedMessageId } = protected_message || {}
  const [{ protected_text, protected_documents = [] }, loading] = useProtectedMessage(protectedMessageId, {
    disabled: hasAction
  })


  const text = React.useMemo(() => {
    if (protected_text) return protected_text
    if (description) return <InfoMessage text={t(description)} />
    if (status === "pending") return <InfoMessage text={t("payment_is_pending")} />
    if (status === "payed") return <InfoMessage text={t("payment_was_successfull")} />
    return null
  }, [protected_text, description, status])

  const Icon = STATUS_ICONS[status]

  if (_.isEmpty(dynamic)) return null
  return (
    <>
      <Text
        size="sm"
        className={join(styles.text, "multiline")}
        component="span"
      >
        {text}
      </Text>
      {!_.isEmpty(protected_documents) &&
        <>
          {protected_documents.map((document) => {
            const { id, file, loading } = document
            return (
              <FileItem
                key={id}
                file={file}
                loading={loading}
              />
            )
          })}
        </>
      }
      <div className={styles.actions}>
        {!hasAction &&
          <div
            className={styles.price}
          >
            <ActionIcon
              color={STATUS_COLORS[status]}
              radius="lg"
              loading={loading}
            >
              <Icon />
            </ActionIcon>
            <Title order={5}>
              {formater.format(price)}
            </Title>

          </div>
        }
        {hasAction &&
          <Button
            radius="lg"
            size="xs"
            leftIcon={<CreditCard size="1.25rem" />}
            loading={loading}
            onClick={() => redirectToPaymentUrl(payment_url)}
            variant="filled"
            color="dark"
            disabled={disabled}
          >
            {`${t("pay")} ${formater.format(price)}`}
          </Button>}
      </div>
    </>
  )
}

function InfoMessage({ text }) {
  return (
    <>
      <Box c="blue" fz="md" fw="bold" component="span">⚠️</Box>{" "}{text}
    </>

  )
}

export default React.memo(DynamicItem)