import React from "react"
import { Button, Card, Text, Title } from "@mantine/core"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import AppPage from "components/AppPage"
import { ArrowRight } from "tabler-icons-react"
import { showModal } from "core/modals"
import RequestModal from "../RequestModal"
import { PageContext } from "core/pages"


function RequestSend() {
  const { t } = useTranslation()
  const { compact } = React.useContext(PageContext)

  if (compact) return null

  return (
    <AppPage className={styles.requestSend}>
      <div className={styles.cardWrap}>
        <Card radius="md" className={styles.card}>
          <div className={styles.left}>
            <Title size={24} className={styles.title}>
              {t("send_request")}
            </Title>
            <Text className={styles.text}>
              {t("send_open_request_description")}
            </Text>
          </div>
          <div className={styles.right}>
            <Button
              radius="md"
              color="dark"
              variant="filled"
              rightIcon={<ArrowRight />}
              onClick={() => showModal(RequestModal)}
            >
              {t("send_request")}
            </Button>
          </div>
        </Card>
      </div>
    </AppPage>
  )
}

export default RequestSend