import { ActionIcon, Button, Checkbox, CloseButton, Loader, Text, TextInput, Tooltip } from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"
import AppModal from "components/AppModal"
import { fetchCurrentUser, selectCurrentUser } from "modules/auth"
import { selectLoaders } from "core/loaders"
import { hideModal } from "core/modals"
import { useFormik } from "formik"
import _ from "lodash"
import { useSelector } from "lodash-redux"
import React from "react"
import { useTranslation } from "react-i18next"
import { Scale, Plus, Search, Send, Trash } from "tabler-icons-react"
import {
  createDocument,
  deleteDocument,
  documentActions,
  useDocuments
} from ".."
import FileItem from "../FileItem"
import styles from "./index.module.scss"
import FileProgress from "./FileProgress"
import FilePayment from "./FilePayment"
import { includesLowerCase } from "core"


function DocumentModal({ requestId, onSubmit = _.noop }) {
  const { t } = useTranslation()
  const dropzoneRef = React.useRef(null)

  const deletingLoaders = useSelector(() => selectLoaders("deleteDocument"))
  const [documents, loading] = useDocuments()

  const formik = useFormik({
    initialValues: {
      qty: 0,
      search: "",
      files: [],
      documentIds: [],
      selectedFiles: [],
      requestId,
      ...documentActions.get()
    },
    validate: (values) => documentActions.update(values),
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        const { documentIds, selectedFiles } = values

        const ids = [...documentIds]
        for (const file of _.clone(selectedFiles)) {
          const { id } = await createDocument({ file })
          formik.setFieldValue("files", _.pull(files, file))
          formik.setFieldValue("selectedFiles", _.pull(selectedFiles, file))
          ids.push(id)
        }

        await onSubmit(ids)
        documentActions.update({
          documentIds: [],
          selectedFiles: [],
          files: []
        })
        hideModal()
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { values } = formik
  const { search = "", files = [], documentIds = [], selectedFiles = [] } = values

  const filteredDocuments = _.union(
    Object.values(documents).filter(({ file }) => includesLowerCase(file.name, search)),
    Object.values(_.pick(documents, documentIds))
  )

  const filteredFiles = _.union(
    files.filter((file) => includesLowerCase(file.name, search)),
    selectedFiles
  )

  const files_size_mb = selectedFiles.reduce((acc, file) => acc + (Number(file.size)) / 1000 / 1000, 0)
  const { upload_limit_mb = 0, upload_total_mb = 0 } = selectCurrentUser()
  React.useEffect(() => {
    fetchCurrentUser()
  }, [])

  const canSend = !_.isEmpty(selectedFiles) || !_.isEmpty(documentIds)
  const noDocuments = _.isEmpty(filteredFiles) && _.isEmpty(filteredDocuments)

  const exceeding_mb = files_size_mb + upload_total_mb - upload_limit_mb
  const exceeding = exceeding_mb > 0
  const count = documentIds.length + selectedFiles.length


  return (
    <AppModal
      name={t("my_documents")}
      className={styles.documentModal}
      toolbar={
        <div className={styles.toolbar}>
          <FileProgress
            upload_total_mb={upload_total_mb}
            upload_limit_mb={upload_limit_mb}
            files_size_mb={files_size_mb}
          />
          <div className={styles.bottom}>
            <TextInput
              className={styles.search}
              name="search"
              variant="filled"
              icon={<Search />}
              placeholder={t("search_for_documents")}
              value={values.search}
              onChange={formik.handleChange}
              rightSection={values.search && (
                <CloseButton
                  onClick={() => formik.setFieldValue("search", "")}
                />
              )}
            />
            <ActionIcon
              size="lg"
              color="dark"
              variant="filled"
              onClick={() => dropzoneRef.current()}
            >
              <Plus />
            </ActionIcon>
          </div>
        </div>
      }
      footer={
        <>
          {exceeding &&
            <FilePayment
              min={exceeding_mb}
              formik={formik}
            />
          }
          {!exceeding &&
            <Button
              leftIcon={<Send />}
              radius="lg"
              color="dark"
              disabled={!canSend}
              loading={formik.isSubmitting}
              onClick={formik.handleSubmit}
            >
              {count
                ? t("send_dynamic_documents", { count })
                : t("send_documents")
              }
            </Button>
          }
        </>
      }
    >
      <Dropzone
        hidden
        openRef={dropzoneRef}
        onDrop={(_files) => {
          formik.setFieldValue("files", [..._files, ...files])
          formik.setFieldValue("selectedFiles", [..._files, ...selectedFiles])
        }}
      />
      <div className={styles.files}>
        {noDocuments && !loading &&
          <div className="centered-content">
            <Text color="gray" size="sm">{t("no_documents_found")}</Text>
          </div>
        }
        {noDocuments && loading &&
          <div className="centered-content">
            <Loader />
          </div>
        }
        {filteredFiles.map((file, index) => {
          const selected = selectedFiles.includes(file)
          return (
            <FileItem
              key={index}
              radius="sm"
              file={file}
              selected={selected}
              error={exceeding && selected}
              highlight={search}
              onClick={() => {
                if (!selected) {
                  formik.setFieldValue("selectedFiles", _.uniq([...selectedFiles, file]))
                } else {
                  formik.setTouched("selectedFiles", true)
                  formik.setFieldValue("selectedFiles", _.pull(selectedFiles, file))
                }
              }}
              extra={
                <Checkbox
                  readOnly
                  size="xs"
                  color={exceeding ? "red" : undefined}
                  checked={selected}
                />
              }
              actions={
                <CloseButton
                  size="sm"
                  onClick={() => {
                    formik.setTouched("files", true)
                    formik.setFieldValue("files", _.pull(files, file))
                    formik.setFieldValue("selectedFiles", _.pull(selectedFiles, file))
                  }}
                />
              }
            />
          )
        })}

        {_.orderBy(filteredDocuments, "id", "desc")
          .map((doc, index) => {
            const { id, file, ignore_upload_limit } = doc
            const selected = documentIds.includes(id)
            const deleting = Boolean(deletingLoaders[id])

            return (
              <FileItem
                key={index}
                radius="sm"
                file={file}
                selected={selected}
                highlight={search}
                onClick={() => {
                  if (!selected) {
                    formik.setFieldValue("documentIds", _.uniq([...documentIds, id]))
                  } else {
                    formik.setTouched("documentIds", true)
                    formik.setFieldValue("documentIds", _.pull(documentIds, id))
                  }
                }}
                extra={
                  <Checkbox
                    size="xs"
                    checked={selected}
                    readOnly
                  />
                }
                actions={
                  <>
                    {!ignore_upload_limit &&
                      <Tooltip label={t("delete_document")} withinPortal>
                        <ActionIcon
                          size="sm"
                          loading={deleting}
                          onClick={async (event) => {
                            event.stopPropagation()
                            await deleteDocument(id)
                            formik.setFieldValue("documentIds", _.pull(documentIds, id))
                          }}
                        >
                          <Trash />
                        </ActionIcon>
                      </Tooltip>
                    }
                    {ignore_upload_limit &&
                      <Tooltip label={t("provider_document")} withinPortal>
                        <ActionIcon
                          size="sm"
                        >
                          <Scale />
                        </ActionIcon>
                      </Tooltip>
                    }
                  </>
                }
              />
            )
          })}
      </div>
    </AppModal>
  )
}

export default DocumentModal