import React from "react"
import { ActionIcon, Button, Text, Title } from "@mantine/core"
import styles from "./index.module.scss"
import {
  Calendar,
  Clock,
  Files,
  Pencil,
  PlayerPause,
  PlayerPlay,
  Plus,
  Report
} from "tabler-icons-react"
import dayjs from "dayjs"
import AppTable from "components/AppTable"
import {
  generateReport,
  getTimesheetInfo,
  updateTimesheet,
  useTimesheets
} from "modules/timesheets"
import { showModal } from "core/modals"
import TimesheetModal from "modules/timesheets/TimesheetModal"
import { clearLoader, setLoader, useLoader } from "core/loaders"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import join from "clsx"
import { useProject } from "modules/projects"
import AppPage from "components/AppPage"
import PageHeader from "components/PageHeader"
import TagFilters from "components/TagFilters"
import { useFormik } from "formik"
import { STATUS_COLORS } from "core"
import PageFooter from "components/PageFooter"
import { DatePickerInput } from "@mantine/dates"
import pageActions, { PageContext, updateValues } from "core/pages"


const TIMESHEET_FILTERS = {
  current_month: "current_month",
  last_month: "last_month"
}


function TimesheetList({ requestId, projectId, timesheetId, startAt, endAt, timesheet_tag }) {
  const { t } = useTranslation()
  const { compact } = React.useContext(PageContext)
  const [project] = useProject(projectId)
  const [timesheets, loading] = useTimesheets({ filters: { project: projectId } }, [projectId])

  const filteredList = React.useMemo(() => Object.values(timesheets).filter((timesheet) => {
    const conditions = []
    if (projectId) conditions.push(timesheet.projectId === projectId)
    if (startAt && endAt) conditions.push(dayjs(timesheet.registerAt).isBetween(startAt, endAt, "day", []))
    return conditions.every(Boolean)
  }), [projectId, timesheets, startAt, endAt])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startAt,
      endAt,
      timesheet_tag
    },
    validate: (values) => updateValues(values)
  })
  const { values } = formik

  return (
    <AppPage
      className={join(styles.timesheetList, compact && styles.compact)}
      header={
        <PageHeader
          icon={<Files />}
          name={t("timesheets")}
          description={project.name}
          showBack={requestId}
          onBack={() => {
            pageActions
              .load("ProjectList")
              .update({ projectId })
              .push()
          }}
          menu={
            <>
              <Button
                radius="lg"
                color="dark"
                variant="outline"
                leftIcon={<Plus />}
                onClick={() => showModal(TimesheetModal, { projectId })}
              >
                {t("new_timesheet")}
              </Button>
              <Button
                radius="lg"
                color="dark"
                leftIcon={<Report />}
                disabled={_.isEmpty(filteredList)}
                onClick={() => generateReport({ timesheets: filteredList, project, t })}
              >
                {t("download_report")}
              </Button>
            </>
          }
          toolbar={
            <div className={styles.toolbar}>
              <div className={styles.row}>
                <DatePickerInput
                  allowSingleDateInRange
                  className={styles.search}
                  clearable
                  name="timesheet_search"
                  icon={<Calendar />}
                  placeholder={t("search_for_timesheets")}
                  value={[
                    values.startAt && dayjs(values.startAt),
                    values.endAt && dayjs(values.endAt)
                  ]}
                  onChange={(range) => {
                    const [startAt, endAt] = range || []
                    formik.setValues({
                      startAt: startAt && dayjs(startAt).toISOString(),
                      endAt: endAt && dayjs(endAt).toISOString(),
                      timesheet_tag: ""
                    })
                  }}
                  variant="filled"
                  type="range"
                  popoverProps={{
                    withinPortal: true
                  }}
                />
                <ActionIcon
                  size="lg"
                  variant="filled"
                  color="dark"
                  onClick={() => showModal(TimesheetModal, { projectId })}
                >
                  <Plus />
                </ActionIcon>
              </div>

            </div>
          }
        />
      }
      footer={!_.isEmpty(filteredList) &&
        <PageFooter>
          <Button
            radius="lg"
            color="dark"
            leftIcon={<Report />}
            disabled={_.isEmpty(filteredList)}
            onClick={() => generateReport({ timesheets: filteredList, project, t })}
          >
            {t("download_report")}
          </Button>
        </PageFooter>
      }
    >
      <TagFilters
        value={values.timesheet_tag}
        options={Object.values(TIMESHEET_FILTERS).map(((value) => ({
          value,
          label: t(value),
          color: STATUS_COLORS[value]
        })))}
        onChange={(timesheet_tag) => {
          let startAt
          let endAt
          switch (timesheet_tag) {
            case (TIMESHEET_FILTERS.current_month): {
              startAt = dayjs().startOf("month").toISOString()
              endAt = dayjs().endOf("month").toISOString()
              break
            }
            case (TIMESHEET_FILTERS.last_month): {
              startAt = dayjs().subtract(1, "month").startOf("month").toISOString()
              endAt = dayjs().subtract(1, "month").endOf("month").toISOString()
              break
            }
            default: // default
          }
          formik.setValues({ timesheet_tag, startAt, endAt })
        }}
      />
      <AppTable
        loading={loading && _.isEmpty(filteredList)}
        rows={_.orderBy(filteredList,
          [({ registerAt }) => dayjs(registerAt).valueOf()],
          ["desc"]
        )}
        columns={[
          {
            id: "timesheet",
            flex: 2,
            render: ({ name }) => {
              return (
                <Title order={6} className="elipsis">
                  {name}
                </Title>
              )
            }
          },
          !compact && {
            id: "date",
            flex: "auto",
            render: ({ registerAt }) => (
              <Text size="xs" style={{ lineHeight: 1 }} color="gray" >
                {dayjs(registerAt).format("L")}
              </Text>
            )
          },
          {
            id: "time",
            flex: "none",
            render: (timesheet) => {
              const { registerAt } = timesheet
              const { hours, minutes, started } = getTimesheetInfo(timesheet)
              return (
                <div className={styles.time}>
                  <>
                    {started && <Clock size="1rem" className={styles.started} /> }
                    {!started && <Clock size="1rem" className={styles.stopped} /> }
                  </>
                  <div className={styles.dateTime}>
                    <Text size="xs" style={{ lineHeight: 1 }}>
                      {formatTime({ hours, minutes })}
                    </Text>
                    {compact &&
                      <Text size="xs" style={{ lineHeight: 1 }} color="gray" >
                        {dayjs(registerAt).format("L")}
                      </Text>
                    }
                  </div>
                </div>
              )
            }
          },
          {
            id: "actions",
            flex: "none",
            render: (timesheet) => <TimesheetActions timesheet={timesheet} />
          }
        ].filter(Boolean)}
      />
    </AppPage>
  )
}


function TimesheetActions({ timesheet }) {
  const { id } = timesheet
  const loading = useLoader(`timesheetUpdate.${id}`)
  const { started, hours, minutes } = getTimesheetInfo(timesheet)
  return (
    <div className={styles.timesheetActions}>
      <ActionIcon
        onClick={() => showModal(TimesheetModal, { timesheetId: id })}
      >
        <Pencil />
      </ActionIcon>
      <>
        {started &&
          <ActionIcon
            color="dark"
            variant="filled"
            loading={loading}
            onClick={async () => {
              try {
                setLoader(`timesheetUpdate.${id}`)
                const startAt = dayjs()
                  .subtract(Number(hours), "hours")
                  .subtract(Number(minutes), "minutes")
                  .toISOString()
                const endAt = dayjs().toISOString()
                await updateTimesheet(id, { startAt, endAt })
              } finally {
                clearLoader(`timesheetUpdate.${id}`)
              }
            }}
          >
            <PlayerPause />
          </ActionIcon>
        }
        {!started &&
          <ActionIcon
            color="dark"
            variant="filled"
            loading={loading}
            onClick={async () => {
              try {
                setLoader(`timesheetUpdate.${id}`)
                const startAt = dayjs()
                  .subtract(Number(hours), "hours")
                  .subtract(Number(minutes), "minutes")
                  .toISOString()
                const endAt = null
                await updateTimesheet(id, { startAt, endAt })
              } finally {
                clearLoader(`timesheetUpdate.${id}`)
              }
            }}
          >
            <PlayerPlay />
          </ActionIcon>
        }
      </>
    </div>
  )
}

const formatTime = (values) => {
  const { hours, minutes } = values || {}
  return `${Number(hours)}h:${_.padStart(minutes, 2, 0)}m`
}

export default TimesheetList
