import { Avatar, Badge, Card, Text, Title } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import _ from "lodash"


function ProviderItem({ provider, actions, onClick, ...props }) {
  const { t } = useTranslation()
  const { profile, specializations = [] } = provider || {}
  const { name, title, photo } = profile || {}
  const { url: photoUrl } = photo || {}

  return (
    <Card
      hidden={_.isEmpty(provider)}
      radius={0}
      className={styles.providerItem}
      {...props}
    >

      <Avatar
        className={styles.avatar}
        radius="50%"
        src={photoUrl}
        alt="photoUrl"
        variant="filled"
      />
      <div className={styles.title}>
        <Title
          order={4}
          onClick={onClick}
          underline
          className={styles.title}
          color="dark"
        >
          {name}
        </Title>
        <Text size="sm" color="gray">{title}</Text>
      </div>
      <div className={styles.specializations}>
        {specializations.map(({ name }) => (
          <Badge
            key={name}
            radius="lg"
            color="gray"
            className={styles.specialization}
          >
            {t(name)}
          </Badge>
        ))}
      </div>
      {actions &&
        <div className={styles.actions} onClick={(event) => event.stopPropagation()}>
          {actions}
        </div>
      }
    </Card>
  )
}

export default React.memo(ProviderItem)
