
import { useSelector } from "lodash-redux"
import React from "react"
import _ from "lodash"
import { clearLoader, setLoader, useLoader } from "core/loaders"
import objectHash from "object-hash"
import { actions } from "core/store"
import { EMPTY_OBJECT } from "core"


export const PAGE_SIZE = 500


export const useData = (path, fetchData, params = {}, deps = [], ...args) => {
  const { disabled, pagination = {}, sort = [] } = params
  const { pageSize = PAGE_SIZE } = pagination

  const key = React.useMemo(() => `useData.${path}.${objectHash(deps)}`, deps)
  const loading = useLoader(key)

  const init = async () => {
    try {
      setLoader(key)
      await fetchData({
        ...params,
        sort: [...sort, "id:desc"],
        pagination: {
          page: 0,
          pageSize,
          ...pagination
        }
      }, ...args)

    } finally {
      clearLoader(key)
    }
  }

  const data = useSelector(() => actions.get(path, EMPTY_OBJECT))
  React.useEffect(() => {
    if (!disabled) init()
  }, [...deps, disabled])


  return [data, loading]
}


export const useItem = (id, path, fetchItem, params, ...args) => {
  const loading = useLoader(`data.useItem.${id}`)
  const item = useSelector(() => actions.get(`${path}.${id}`, EMPTY_OBJECT))
  const { disabled } = params || {}
  const canFetchItem = id && !loading && _.isEmpty(item)
  React.useEffect(() => {
    if (!disabled && canFetchItem) {
      const init = async () => {
        try {
          setLoader(`data.useItem.${id}`)
          await fetchItem(id, params, ...args)
        } finally {
          clearLoader(`data.useItem.${id}`)
        }
      }
      init()
    }
  }, [id, canFetchItem, disabled])
  return [item, loading && _.isEmpty(item)]
}
