import React from "react"
import _ from "lodash"
import join from "clsx"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import { ActionIcon, Button, Card, Loader, Text } from "@mantine/core"
import { InfoCircle } from "tabler-icons-react"


function AppTable({
  columns = [],
  rows = [],
  onRowClick,
  loading,
  className,
  isSelected = _.noop,
  isDisabled = _.noop,
  pagination,
  readOnly,
  placeholder,
  ...props
}) {
  const list = Object.values(rows).filter(Boolean)
  const { t } = useTranslation()
  const { done, next } = pagination || {}

  return (
    <div
      {...props}
      className={join(styles.appTable, className)}
    >
      {_.isEmpty(list) &&
        <div className={styles.centered}>
          {loading && <Loader />}
          {!loading &&
            <>
              {placeholder ||
                <div className={styles.row}>
                  <ActionIcon
                    size="xs"
                    style={{ cursor: "auto" }}
                    variant="subtle"
                    radius="lg"
                  >
                    <InfoCircle />
                  </ActionIcon>
                  <Text color="gray" size="sm">
                    {t("no_data_available")}
                  </Text>
                </div>

              }
            </>


          }
        </div>
      }
      {list.map((row, index) => {
        const { id } = row
        const selected = isSelected(row)
        const disabled = readOnly || isDisabled(row)

        return (
          <Card
            className={join(
              styles.row,
              selected && styles.selected,
              onRowClick && styles.clickable,
              disabled && styles.disabled
            )}
            key={id}
            onClick={() => {
              if (onRowClick && !disabled) onRowClick(row)
            }}
          >
            {columns.map((column) => {
              const {
                id: columnId,
                render,
                className,
                ...style
              } = column


              return (
                <div
                  key={columnId}
                  className={join(styles.cell, className)}
                  style={style}
                >
                  {render && render(row, { index })}
                  {!render && <Text size="sm">{_.get(row, columnId)}</Text>}
                </div>
              )
            })}
          </Card>
        )
      })}
      {!done && !loading && !_.isEmpty(pagination) &&
        <Button
          onClick={next}
          compact
          variant="subtle"
          size="xs"
          className={styles.showMore}
        >
          {t("show_more")}
        </Button>
      }
    </div>
  )
}

export default React.memo(AppTable)

