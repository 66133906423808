import { Avatar, Button, Text, Title } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./index.module.scss"
import { Ban, Folders, InputSearch, User } from "tabler-icons-react"
import { useIsProvider } from "modules/auth"
import join from "clsx"
import PageHeader from "components/PageHeader"
import { blockRequest, getRequestInfo } from "modules/requests"
import { Link } from "react-router-dom"
import { modals } from "@mantine/modals"
import pageActions from "core/pages"
import { showModal } from "core/modals"
import ClientModal from "modules/clients/ClientModal"
import FavoriteIcon from "modules/clients/FavoriteIcon"


function ChatHeader({ request, compact }) {
  const { t } = useTranslation()
  const isProvider = useIsProvider()
  const { id, members, blocked } = request
  const [provider] = members || []
  const { id: providerId } = provider || []
  const { name, description, photoUrl, initials } = getRequestInfo({ isProvider, request })

  const handleClick = () => {
    if (isProvider) {
      showModal(ClientModal, { requestId: id })
    } else {
      pageActions.load("ProviderView").update({ providerId: provider.id }).push()
    }
  }

  return (
    <PageHeader
      className={join(styles.chatHeader, compact && styles.compact)}
      onClick={handleClick}
      onBack={() => pageActions
        .load("RequestList")
        .update({ requestId: id })
        .replace()
      }
      icon={
        <Avatar
          src={photoUrl}
          size="2.5rem"
          color="gray"
          radius="50%"
          variant="light"
          className={styles.avatar}
          onClick={handleClick}
        >
          {initials}
        </Avatar>
      }
      name={
        <div className={styles.row}>
          {name}
          <FavoriteIcon requestId={id} size="sm" />
        </div>
      }
      description={description}
      menu={
        <>
          {!isProvider &&
            <Button
              radius="lg"
              color="dark"
              leftIcon={<User />}
              component={Link}
              to={pageActions
                .load("ProviderView")
                .update({ providerId })
                .path
              }
            >
              {t("provider_profile")}
            </Button>
          }
          {isProvider &&
            <>
              <Button
                radius="lg"
                color="dark"
                leftIcon={<User />}
                onClick={() => showModal(ClientModal, { requestId: id })}
              >
                {t("client_profile")}
              </Button>
              <Button
                radius="lg"
                color="blue"
                variant="outline"
                component="a"
                href="https://portal.just.ro/SitePages/acasa.aspx"
                target="_blank"
                leftIcon={<InputSearch />}
              >
                {t("portal_just")}
              </Button>
              <Button
                radius="lg"
                color="dark"
                variant="outline"
                leftIcon={<Folders />}
                component={Link}
                to={pageActions.load("ProjectList").update({ requestId: id }).path}
              >
                {t("projects")}
              </Button>
              {!blocked &&
                <>
                  <Button
                    radius="lg"
                    variant="outline"
                    leftIcon={<Ban />}
                    color="red"
                    onClick={() => {
                      modals.openConfirmModal({
                        confirmProps: {
                          radius: "lg",
                          variant: "filled",
                          color: "red"
                        },
                        cancelProps: {
                          radius: "lg",
                          variant: "outline",
                          color: "dark"
                        },
                        title: <Title order={5}>{t("block")}</Title>,
                        children: (
                          <Text size="sm">
                            {t("are_you_sure_you_want_to_block_this_user")}
                          </Text>
                        ),
                        labels: { confirm: t("confirm"), cancel: t("cancel") },
                        onConfirm: () => blockRequest(id)
                      })

                    }}
                  >
                    {t("block")}
                  </Button>
                </>
              }
            </>
          }
        </>
      }
    />
  )
}

export default React.memo(ChatHeader)

