import { ActionIcon, Button, Text } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import { Scale, Switch3, Trash } from "tabler-icons-react"
import ProviderItem from "modules/providers/ProviderItem"
import { Link } from "react-router-dom"
import { hideModal } from "core/modals"
import pageActions from "core/pages"


function ProviderSection({ formik, provider }) {
  const { t } = useTranslation()
  const { values } = formik
  const { providerId } = values

  const description = providerId
    ? t("provider_selected_description")
    : t("no_provider_selected_description")

  return (
    <>
      <Text size="sm">{description}</Text>
      {providerId &&
        <>
          <ProviderItem
            provider={provider}
            actions={
              <ActionIcon
                size="lg"
                onClick={() => formik.setFieldValue("providerId", null)}
              >
                <Trash />
              </ActionIcon>
            }
          />
          <Button
            radius="lg"
            color="dark"
            variant="outline"
            leftIcon={<Switch3 />}
            className={styles.button}
            onClick={() => hideModal()}
            component={Link}
            to={pageActions.load("ProviderList").path}
          >
            {t("change_provider")}
          </Button>

        </>
      }
      {!providerId &&
        <>
          <Button
            radius="lg"
            color="dark"
            variant="outline"
            leftIcon={<Scale />}
            className={styles.button}
            component={Link}
            onClick={() => hideModal()}
            to={pageActions.load("ProviderList").path}
          >
            {t("add_provider")}
          </Button>
        </>
      }
    </>
  )
}

export default React.memo(ProviderSection)
