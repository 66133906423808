import Axios from "axios"
import cleanDeep from "clean-deep"
import { logout, selectAuth } from "modules/auth"
import _ from "lodash"
import qs from "qs"
import i18n from "./i18n"
import dayjs from "dayjs"
import { setAlertError, setAlertInfo } from "./alerts"


const EMPTY_OBJECT = {}
const {
  REACT_APP_API = "https://api-dev.avochat.net/api",
  REACT_APP_WS = "https://api-dev.avochat.net"
} = process.env

export const NO_AUTHORIZATION = "no-auth"
export const API_URL = REACT_APP_API
export const WS_URL = REACT_APP_WS

const axios = Axios.create()

axios.defaults.paramsSerializer = (params) => qs.stringify(
  cleanDeep(params, { NaNValues: true }),
  { indices: true, encodeValuesOnly: true }
)


axios.defaults.baseURL = API_URL
axios.interceptors.request.use(
  async (config) => {
    const { headers, params } = config
    let { authorization } = headers

    const locale = i18n.language
    if (authorization === NO_AUTHORIZATION) {
      authorization = undefined
    } else {
      const { token, expireAt } = selectAuth()
      if (token && dayjs().isAfter(expireAt)) await debouncedLogout()
      if (!authorization && token) authorization = `Bearer ${token}`
    }

    return {
      ...config,
      headers: { ...headers, authorization },
      params: { ...params, locale }
    }
  },
  (error) => Promise.reject(error)
)

export const debouncedLogout = _.debounce(() => logout(), 100)

axios.interceptors.response.use(
  (response) => _.get(cleanDeep(response), "data", EMPTY_OBJECT),
  async (error) => {
    const _error = new Error()
    const { response } = error || {}
    const { data } = response || {}
    const { error: strapiError } = data || {}
    const { details } = strapiError || {}
    const { errors = [] } = details || {}
    const errorData = errors.reduce((acc, detail) => {
      const { path, message } = detail
      _.set(acc, path, message)
      return acc
    }, {})
    _error.data = errorData
    _error.message =
      error?.response?.data?.message ||
      error?.response?.data?.error?.message ||
      error?.message

    return Promise.reject(_error)
  }
)


export default axios