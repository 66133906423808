import { actions } from "core/store"
import React from "react"
import _ from "lodash"
import styles from "constants.module.scss"
import { useSelector } from "lodash-redux"


const getBreakpoints = (width) => ({
  width,
  sm: width <= parseInt(styles.sm),
  md: width <= parseInt(styles.md),
  lg: width <= parseInt(styles.lg)
})

const DEFAULT_VALUES = getBreakpoints(Number(window?.innerWidth || 0))

export const useBreakpoint = (breakpoint) => {

  const hasBreakpoint = useSelector(() => actions.get(`context.breakpoints.${breakpoint}`, DEFAULT_VALUES))
  return hasBreakpoint
}

export const useBreakpointListener = () => {
  const listener = React.useRef()
  React.useEffect(() => {
    actions.set("context.breakpoints", getBreakpoints(Number(window.innerWidth)))
    listener.current = _.throttle(() => {
      const width = Number(window.innerWidth)
      actions.set("context.breakpoints", getBreakpoints(width))
    }, 200, { leading: false, trailing: true })

    window.addEventListener("resize", listener.current)
    return () => window.removeEventListener("resize", listener.current)
  }, [])
}