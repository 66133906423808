import { Avatar, Button, Flex, Menu, Text, Title } from "@mantine/core"
import { getUserInfo, selectCurrentUser } from "modules/auth"
import { t } from "i18next"
import React from "react"
import { Power, Settings } from "tabler-icons-react"
import styles from "./index.module.scss"
import { debouncedLogout } from "core/axios"
import pageActions from "core/pages"
import AppModal from "components/AppModal"
import { useSelector } from "lodash-redux"
import { hideModal } from "core/modals"
import UserLinks from "../UserLinks"


function UserModal() {
  const user = useSelector(() => selectCurrentUser())
  const { email } = user
  const { initials, photoUrl, name } = getUserInfo(user)

  return (
    <AppModal size="xs">
      <div className={styles.content}>
        <Avatar
          radius="50%"
          size="8rem"
          variant="light"
          src={photoUrl}
          className={styles.avatar}
        >
          {initials}
        </Avatar>
        <div className={styles.center}>
          <Title order={3} lineClamp={2}>{name}</Title>
          <Text lineClamp={2} color="gray">{email}</Text>
        </div>
        <UserLinks />
      </div>
      <Flex direction="column" gap=".5rem" style={{ padding: ".5rem" }}>
        <Button
          radius="lg"
          variant="filled"
          color="dark"
          leftIcon={<Settings />}
          onClick={() => {
            hideModal()
            pageActions.load("SettingsView").push()
          }}
        >
          {t("settings")}
        </Button>
        <Button
          radius="lg"
          variant="outline"
          color="dark"
          leftIcon={<Power />}
          onClick={() => {
            hideModal()
            debouncedLogout()
          }}
        >
          {t("logout")}
        </Button>
      </Flex>
    </AppModal>
  )
}

export default React.memo(UserModal)