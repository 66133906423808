import { ActionIcon, Modal, Text, Title } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import { hideModal, ModalContext } from "core/modals"
import { X } from "tabler-icons-react"
import join from "clsx"
import { useBreakpoint } from "core/pages"

function AppModal({
  className,
  name,
  icon,
  description,
  footer,
  children,
  actions,
  toolbar,
  onClose = hideModal,
  ...props
}) {
  return (
    <Modal
      radius="lg"
      centered
      opened
      withinPortal
      withCloseButton={false}
      closeOnClickOutside={false}
      size="lg"
      onClose={onClose}
      padding={0}
      classNames={{
        content: join(styles.appModal, className),
        body: styles.modalBody
      }}
      {...props}
    >
      <div className={styles.header}>
        <div className={styles.left}>
          {icon}
          <div className={styles.name}>
            <Title order={description ? 5 : 4} className="elipsis" >{name}</Title>
            {description &&
              <Text size="sm" color="gray" className="elipsis">
                {description}
              </Text>
            }
          </div>
          <ActionIcon onClick={onClose} size="md" className={styles.close}>
            <X />
          </ActionIcon>
        </div>
        {toolbar &&
          <div className={styles.toolbar}>
            {toolbar}
          </div>
        }
      </div>
      <div className={styles.content}>
        {children}
      </div>
      {footer &&
        <div className={styles.footer} onClick={(event) => event.stopPropagation()}>
          {footer}
        </div>
      }
    </Modal>
  )
}

export default React.memo(AppModal)
