import { ActionIcon } from "@mantine/core"
import { clearLoader, setLoader, useLoader } from "core/loaders"
import { useIsProvider } from "modules/auth"
import { useRequest } from "modules/requests"
import React from "react"
import { Star } from "tabler-icons-react"
import { updateClientRequest } from ".."


function FavoriteIcon({ requestId, size = "xs" }) {
  const isProvider = useIsProvider()
  const [request] = useRequest(requestId)
  const loading = useLoader(`enablingCRM.${requestId}`)
  const { provider_client } = request || {}
  const { enabledCRM } = provider_client || {}


  if (!isProvider) return null
  return (
    <ActionIcon
      loading={loading}
      size={size}
      variant="transparent"
      color={enabledCRM ? "yellow" : "gray"}
      style={{ opacity: (enabledCRM || loading) ? 1 : .25 }}
      onClick={async (event) => {
        event.stopPropagation()
        try {
          setLoader(`enablingCRM.${requestId}`)
          await updateClientRequest(requestId, { enabledCRM: !enabledCRM })
        } finally {
          clearLoader(`enablingCRM.${requestId}`)
        }

      }}
    >
      <Star />
    </ActionIcon>
  )
}

export default React.memo(FavoriteIcon)