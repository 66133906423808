import { useBreakpoint } from "core/layout"
import React from "react"
import AppFooter from "./AppFooter"
import AppSidebar from "./AppSidebar"
import join from "clsx"
import styles from "./index.module.scss"
import { useSelector } from "lodash-redux"
import { actions } from "core/store"


function AppLayout({ children }) {
  const md = useBreakpoint("md")
  const hasFooter = useSelector(() => actions.get("hasFooter"))

  return (
    <div className={join(styles.appLayout, md && styles.md)}>
      {!md && <AppSidebar />}
      {children}
      {md && !hasFooter && <AppFooter />}
    </div>
  )
}

export default AppLayout
