import qs from "qs"
import _ from "lodash"
import history, { useSearch } from "core/history"
import { matchPath, useLocation } from "react-router-dom"
import React from "react"
import { EMPTY_OBJECT } from "core"
import { useSelector } from "lodash-redux"
import { actions } from "core/store"
import styles from "constants.module.scss"

const createPageActions = (name, values) => {
  const path = getPagePath(name, values)
  const actions = {
    load: (payload) => {
      const name = getPage()
      if (_.isFunction(payload)) return createPageActions(payload(name), values)
      return createPageActions(_.defaultTo(payload, name), values)
    },
    set: (values) => createPageActions(name, values),
    update: (payload) => {
      const values = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      if (_.isFunction(payload)) return createPageActions(name, payload(values))
      return createPageActions(name, { ...values, ...payload })
    },
    push: () => history.push(path),
    replace: () => history.replace(path),
    path
  }

  return actions
}


export const usePage = () => {
  const location = useLocation()
  const name = React.useMemo(() => getPage(location), [location])
  const values = useSearch()
  return [name, values]
}

const getPage = (location = window.location) => {
  const match = matchPath(location.pathname, { path: "/page/:name?" })
  const { params } = match || {}
  const { name } = params || {}
  return name
}

const getPagePath = (name, values) => {
  let path = ["/page", name].filter(Boolean).join("/")
  path = [path, values && qs.stringify(values)].filter(Boolean).join("?")
  return path
}


export const PageContext = React.createContext(EMPTY_OBJECT)


export const useBreakpoint = (breakpoint) => {
  const hasBreakpoint = useSelector(() => actions.get(`context.breakpoints.${breakpoint}`))
  return hasBreakpoint
}

export const useBreakpointListener = () => {
  const listener = React.useRef()
  React.useEffect(() => {
    actions.set("context.breakpoints", getBreakpoints(Number(window.innerWidth)))
    listener.current = _.throttle(() => {
      const width = Number(window.innerWidth)
      actions.set("context.breakpoints", getBreakpoints(width))
    }, 200, { leading: false, trailing: true })

    window.addEventListener("resize", listener.current)
    return () => window.removeEventListener("resize", listener.current)
  }, [])
}
export const closePage = () => pageActions.load().update().push()
const pageActions = createPageActions()

export const updateValues = _.debounce((values) => pageActions.load().update(values).replace(), 150, {
  leading: false,
  trailing: true
})


const getBreakpoints = (width) => ({
  width,
  sm: width <= parseInt(styles.sm),
  md: width <= parseInt(styles.md),
  lg: width <= parseInt(styles.lg)
})
export default pageActions