import { Card } from "@mantine/core"
import React from "react"
import styles from "./index.module.scss"
import join from "clsx"
import { PageContext } from "core/pages"


function PageFooter({ children, className }) {
  const { compact } = React.useContext(PageContext)

  if (!children) return null
  return (
    <Card
      radius={0}
      className={join(styles.pageFooter, compact && styles.compact, className)}
    >
      {children}
    </Card>
  )
}

export default React.memo(PageFooter)