import { ActionIcon, Button, Input, SegmentedControl, Switch, Textarea, TextInput } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  AddressBook,
  Building,
  Check,
  Code,
  DeviceFloppy,
  Lock,
  Mail,
  Phone,
  Star,
  StarHalf,
  User,
  UserCheck,
  UserMinus,
  UserOff,
  UserX
} from "tabler-icons-react"
import { useFormik } from "formik"
import {
  CLIENT_TYPES,
  updateClientRequest
} from ".."
import { setAlertError } from "core/alerts"
import styles from "./index.module.scss"
import { getRequestInfo, useRequest } from "modules/requests"
import AppModal from "components/AppModal"
import { hideModal } from "core/modals"
import _ from "lodash"


const INITIAL_VALUES = {
  enabledCRM: false,
  description: "",
  type: CLIENT_TYPES.person,
  identity_code: "",
  register_number: "",
  address: "",
  phone: ""
}

function ClientModal({ requestId }) {
  const { t } = useTranslation()
  const [request] = useRequest(requestId)
  const { provider_client = {} } = request
  const info = getRequestInfo({ request, isProvider: true })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: _.pick({
      ...INITIAL_VALUES,
      ...provider_client
    }, Object.keys(INITIAL_VALUES)),
    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true)
        await updateClientRequest(requestId, values)
        formik.resetForm()
        hideModal()
      } catch (error) {
        setAlertError(error.message)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const { values, errors, touched } = formik
  const {
    description,
    enabledCRM
  } = values

  if (!requestId) return null
  return (
    <AppModal
      className={styles.clientModal}
      icon={<User />}
      name={t("client_profile")}
      footer={
        <>
          <Button
            radius="lg"
            color="dark"
            className={styles.button}
            leftIcon={<DeviceFloppy />}
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            {t("save")}
          </Button>
        </>
      }
    >
      <TextInput
        variant="filled"
        label={t("name")}
        name="name"
        value={info.name}
        readOnly
        icon={<User />}
        rightSection={<ActionIcon><Lock /></ActionIcon>}
      />
      <TextInput
        variant="filled"
        label={t("email")}
        name="contact_email"
        value={info.email}
        readOnly
        icon={<Mail />}
        rightSection={<ActionIcon><Lock /></ActionIcon>}
      />
      <Textarea
        label={t("description")}
        variant="filled"
        name="description"
        placeholder={t("type_a_description")}
        value={description}
        onChange={formik.handleChange}
        maxLength={2000}
        minRows={5}
        className={styles.textarea}
        error={touched.description && errors.description}
      />
      <Input.Wrapper
        label={t("favorite")}
      >
        <ActionIcon
          variant="transparent"
          color={enabledCRM ? "yellow" : "gray"}
          style={{ opacity: enabledCRM ? 1 : .25 }}
          onClick={() => formik.setFieldValue("enabledCRM", !enabledCRM)}
        >
          <Star />
        </ActionIcon>
      </Input.Wrapper>
    </AppModal>
  )
}

export default ClientModal
